import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  PowerSettingsNew as LogoutIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router";

import { logout } from "./../../state/ducks/auth/operations";
import { toggleSideMenu } from "./../../state/ducks/utils/operations";
import Constant from "../../util/constant";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
});

const SideMenu = (props) => {
  const classes = useStyles();

  const navigateToHome = () => {
    props.toggleSideMenu();
    props.history.push("/");
  };

  const navigateToProfile = () => {
    props.toggleSideMenu();
    props.history.push(`/profile`);
  };

  const navigateToAccount = () => {
    props.toggleSideMenu();
    props.history.push(`/account`);
  };

  const onLogout = () => {
    props.toggleSideMenu();
    props.logout();
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={() => toggleSideMenu()}
      onKeyDown={() => toggleSideMenu()}
    >
      <List>
        <ListItem
          button
          onClick={navigateToHome}
          selected={Constant.MENU.HOME === props.selectedMenu}
        >
          <ListItemIcon>
            <HomeIcon></HomeIcon>
          </ListItemIcon>
          <ListItemText primary={"HOME"} />
        </ListItem>
        <ListItem
          button
          onClick={navigateToProfile}
          selected={Constant.MENU.PROFILE === props.selectedMenu}
        >
          <ListItemIcon>
            <PersonIcon></PersonIcon>
          </ListItemIcon>
          <ListItemText primary={"PROFILE"} />
        </ListItem>
        <ListItem
          button
          onClick={navigateToAccount}
          selected={Constant.MENU.ACCOUNT === props.selectedMenu}
        >
          <ListItemIcon>
            <SettingsIcon></SettingsIcon>
          </ListItemIcon>
          <ListItemText primary={"ACCOUNT"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"LOGOUT (GY5H)"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Drawer open={props.sideMenu} onClose={() => props.toggleSideMenu()}>
      {list()}
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenu: state.utils.sideMenu,
    profile: state.auth.session.user,
    selectedMenu: state.utils.selectedMenu,
  };
};

const mapDispatchToProps = { toggleSideMenu, logout };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
