import React, { useState } from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@material-ui/core";
import Message from "../../util/message";
import Constant from "../../util/constant";
import StorageService from "../../services/localstorage.service";
import {
  login,
  initializeSession,
  authenticated,
} from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import "./Login.scss";
const LOGIN_FORM = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.PasswordEmpty,
      },
    },
  },
};

const Login = (props) => {
  const history = useHistory();
  const [loginForm, setLoginForm] = useState(null);
  const userName = get(props, "location.state.userName", "");

  const onFormSubmit = async () => {
    try {
      if (Object.keys(loginForm.errors).length) return;
      const { email, password } = loginForm.getValues();
      const response = await props.login({ email, password });
      const { refreshToken, isEmailVerified, user, idToken } = response.data;

      if (!isEmailVerified) {
        props.history.push({
          pathname: "/confirm-email",
          state: { email, password, fromLogin: true, userName },
        });
      } else {
        const tokens = { accessToken: idToken, refreshToken };
        props.initializeSession({ user, tokens });
        props.authenticated();
        if (userName) {
          props.history.push(`u/${userName}`);
        } else {
          props.history.push("/");
        }
      }
    } catch (err) {
      props.showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const redirectToSignup = () => {
    const navState = userName ? { userName } : {};
    props.history.push({
      pathname: "/signup",
      state: navState,
    });
  };

  const redirectToForgotPassword = () => {
    props.history.push("/forgot-password");
  };

  return (
    <Box height={1} display="flex" flexDirection="column" className="overFlow">
      <Box
        bgcolor="black"
        color="white"
        className="brand-cover-container loginTop"
      >
        <Box p="1rem" color="white" className="cover-content">
          <Box
            className="appName cursor"
            onClick={() => history.push("/welcome")}
          >
            <Box className="topButton">
              <span>
                <img src={mainLogo} alt="logo" />
              </span>
              <Typography variant="h5" color="inherit">
                Panfavorite
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="brand-cover marBottom0"></Box>
        <Typography variant="h3" className="pageName">
          Sign In
        </Typography>
      </Box>
      <Box flex={1} className="bglight loginBotton">
        <Container maxWidth="sm">
          <HookForm
            defaultForm={{
              email: StorageService.getItem("email"),
              password: StorageService.getItem("password"),
            }}
            init={(loginForm) => setLoginForm(loginForm)}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="2rem"
                  height={1}
                >
                  <Box className="auth-card">
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={LOGIN_FORM.email.validate}
                        name={LOGIN_FORM.email.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder="Email Address"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={LOGIN_FORM.password.validate}
                        name={LOGIN_FORM.password.name}
                        errors={formMethod?.errors}
                        type={"password"}
                        placeholder="Password"
                      />
                    </Box>
                    <Box
                      className="rememberOut"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            onChange={() => { }}
                            name="rember"
                            color="primary"
                          />
                        }
                        className="gray-font"
                        label="Remember me"
                      /> */}
                      <Link onClick={redirectToForgotPassword}>
                        <Typography className="gray-font">
                          Forgot password?
                        </Typography>
                      </Link>
                    </Box>
                    <Box className="loginOut noGoogleBt">
                      <CustomButton
                        type="submit"
                        title="LOGIN HERE"
                        disabled={!formMethod?.formState.isValid}
                        loading={!!props.busy}
                      />
                    </Box>
                    {/* <Box className="marBottom0" mt={3} >
                      <CustomButton 
                        color="secondary"
                        variant="outlined"
                        type="submit"
                        title="SIGN IN WITH GOOGLE"
                      />
                    </Box> */}
                  </Box>
                  <Box
                    className="regiLink"
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                    width={1}
                    color="black"
                  >
                    <Link onClick={redirectToSignup}>
                      <Typography className="black-font register-link">
                        New user register here
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              );
            }}
          </HookForm>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {
  login,
  showToast,
  authenticated,
  initializeSession,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
