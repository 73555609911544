const Constant = {
    REGEX: {
        PHONE_NUMBER: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
        email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        SPECIAL_CHAR: /[!@#\$%\^\&*\)\(+=._-]/g,
        NUMBER: /^\d+$/,
        CONTAIN_NUMBER: /[0-9]/g,
        DECIMAL: /^[0-9]*(\.[0-9]{0,2})?$/i,
        DECIMAL_GREATER_ZERO:/[0-9]*\.?[0-9]*[2-9]/,
        Name: /^[ a-zA-Z]+$/i,
        Alphanumeric: /^[ a-zA-Z0-9\.\-\,]+$/i,
        LOWERCASE_UPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
        Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
        commission: /[1-9]$|^[1-9][0-9]$|^(100)$/i,
        amount: /^[$0-9]*$/,
    },
    Message: {

    },
    FILE_TYPES: {
        AUDIO: 'audio',
        VIDEO: 'video',
        IMAGE: 'image',
        FILE: 'file'
    },
    COVER_FILE_TYPES: ["image/jpeg", "image/jpg", "image/png"],
    COVER_BUCKET_PATH: 'https://spoonfeed-images.s3.amazonaws.com/cover',
    RECIPE_BUCKET_PATH: 'https://spoonfeed-images.s3.amazonaws.com/recipe',
    PROFILE_FILE_TYPES: ["image/jpeg", "image/jpg", "image/png"],
    PROFILE_BUCKET_PATH: 'https://spoonfeed-images.s3.amazonaws.com/profile',
    DEFAULT_IMAGE_PATH: 'https://spoonfeed-images.s3.amazonaws.com/images',
    DEFAULT_USER_IMAGE : 'user.png',
    DEFAULT_RECIPE_IMAGE : 'recipe-book.png', 
    WELCOME_VIDEO : 'welcome_video.mp4',   
    MENU: {
        HOME: 'home',
        PROFILE: 'profile',
        ACCOUNT: 'account',
        SAVEDRECIPE: 'SavedRecipe'
    }
}


export default Constant