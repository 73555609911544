import React, { useRef } from "react";
import { connect } from "react-redux";
import { Box, Container, Icon } from "@material-ui/core";
import Constant from "../../util/constant";
import Message from "../../util/message";
import CustomButton from "./../CustomButton/CustomButton";
import { showToast } from "../../state/ducks/utils/operations";
import "./CoverUploader.scss"
import { FiEdit3 } from "react-icons/fi";

const CoverUploader = (props) => {
  const fileUploadRef = useRef();

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length) {
      const file = files[0];
      if (Constant.COVER_FILE_TYPES.includes(file.type)) {
        props.onChange && props.onChange(file);
      } else {
        props.showToast({
          message: Message.ErrorMessage.CoverPicType,
          type: 'error'
        });
      }
    }
    clearData();
  }

  const openFilePicker = () => fileUploadRef.current && fileUploadRef.current.click();

  const clearData = () => fileUploadRef.current && (fileUploadRef.current.value = '')

  return (
    <>
        <input type='file' onChange={handleFileChange} ref={fileUploadRef} className='hidden-file-picker' accept="image/gif,image/jpeg,image/png" /> 
        <CustomButton  className="smButtom editOut"
          title={
            <Box display="flex" justifyContent="center" alignItems="center" >
              <Box className="tikIcon">
                <FiEdit3/>
              </Box>
              {/* <Box>COVER PIC</Box>  */}
            </Box>
          }
          loading={!!props.busy}
          fullWidth={false}
          onClick={openFilePicker} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

export default connect(mapStateToProps, mapDispatchToProps)(CoverUploader);
