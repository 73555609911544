import React, { useState } from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Box,
  Typography
} from "@material-ui/core";

import Message from "../../util/message";
import Constant from "../../util/constant";
import { resetPassword } from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";

import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";

import "./ResetPassword.scss";

const RESET_PASSWORD_FORM = {
  code: {
    name: 'code',
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.CodeEmpty
      }
    }
  },
  password: {
    name: 'password',
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.PasswordEmpty
      },
      validate: {
        length: (value) => (value && value.length >= 8) || 'Minimum 8 characters required',
        hasSpecialChar: (value) => (value && value.match(Constant.REGEX.SPECIAL_CHAR)) || 'Minimum 1 special character required',
        hasNumbers: (value) => (value && value.match(Constant.REGEX.CONTAIN_NUMBER)) || 'Minimum 1 number required',
        hasLowerUpper: (value) => (value && value.match(Constant.REGEX.LOWERCASE_UPPERCASE)) || 'Minimum 1 number required',
      },
    }
  },
};

const ConfirmEmail = (props) => {
  const [resetPasswordForm, setResetPasswordForm] = useState(null);
  const email = get(props, 'location.state.email', '');


  const onFormSubmit = async () => {
    try {
      if (Object.keys(resetPasswordForm.errors).length) return;
      const { code, password } = resetPasswordForm.getValues();
      await props.resetPassword({ email, code, password });
      props.showToast({
        message: 'You have successfully reset your password. Login with new password to continue.',
        type: 'success'
      })
      props.history.push('/')
    } catch (err) {
      props.showToast({
        message: get(err, 'response.data.message', Message.ErrorMessage.API_ERROR),
        type: 'error'
      })
    }
  };

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box height={1 / 3} bgcolor="black" color="white" className="brand-cover-container">
        <Box p="1rem" color="white" className="cover-content">
           <Box className="appName">
              <span>
                <img src={mainLogo} alt="logo" />
              </span>
              <Typography variant="h5" color="inherit">
                Panfavorite
              </Typography>
           </Box>
        </Box>
        <Box className="brand-cover"></Box>
        <Typography variant="h3" className="pageName" >Reset Password</Typography>
      </Box>
      <Box  flex={1} className="bglight loginBotton">
        <Container maxWidth="sm">
          <HookForm
            defaultForm={{}}
            init={setResetPasswordForm}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box display="flex" flexDirection="column" mt='2rem' height={1}>
                  <Box marginBottom="2rem">
                    <Typography align="center" className='gray-font'>
                      {`Please provide the confirmation code you have received at ${email} along with password you want to set.`}
                    </Typography>
                  </Box>
                  <Box className='auth-card'>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={RESET_PASSWORD_FORM.code.validate}
                        name={RESET_PASSWORD_FORM.code.name}
                        errors={formMethod?.errors}
                        type={"text"}
                        placeholder="Code"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={RESET_PASSWORD_FORM.password.validate}
                        name={RESET_PASSWORD_FORM.password.name}
                        errors={formMethod?.errors}
                        type={"password"}
                        placeholder="Password"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <CustomButton
                        type="submit"
                        title="SUBMIT"
                        disabled={!formMethod?.formState.isValid}
                        loading={!!props.busy}
                      />
                    </Box></Box>
                </Box>
              );
            }}
          </HookForm>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {
  resetPassword,
  showToast
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail));
