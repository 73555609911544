import React, { useState, useEffect } from "react";
import { Container, Box, Typography, TextField, Avatar, Button, Link,  } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { connect } from "react-redux";
import { AiOutlineHeart } from "react-icons/ai";
import { RiChat3Line } from "react-icons/ri";
import { get } from "lodash";
import { withRouter,useLocation } from "react-router-dom"; 
import Loader from '../../loader';
import "./Comments.scss";
import { showToast } from "../../state/ducks/utils/operations";
import Message from "../../util/message";
import { getUserRecipesComments, getRecipe,addComment } from "../../state/ducks/recipe/operations";
import SubComment from "../../components/SubComment/SubComment";


 const Comments = (props) => {

    const {
        showToast,  
        profile,
        recipe,
        comments,
        getRecipe,
        getUserRecipesComments,
        addComment
      } = props;
    

    const [isActive, setActive] = useState(false);
    const [loader, setLoader] = React.useState(false);
    const [subcomment, setSubcomment] = useState(false);
    const [comment, setComment] = useState("");

    const handleToggle = (index) => 
        {
        setActive(!isActive); 
        };
    
    const onChange=(e)=>{
        setComment(e.target.value);
    }

    const saveComment=async()=>{
        setLoader(true);
        try {
        const formData = new FormData();
        formData.append("user_id", profile.id);
        formData.append("recipe_id", recipe.id);
        formData.append("comments", comment);
        formData.append("parent_comment_id", 0);

        await addComment(formData);

        setLoader(false);

        showToast({
        message: Message.SuccessMessage.CommentCreated,
        type: "success",
        });


    } catch (err) {
        setLoader(false);
        showToast({
          message: get(
            err,
            "response.data.message",
            Message.ErrorMessage.API_ERROR
          ),
          type: "error",
        });
      }
    }



        useEffect(() => {
            const {
                match: { params },
              } = props;
            getUserRecipesComments(params.recipe_id);
            getRecipe(params.recipe_id,profile.id);
        },[subcomment]);

    return (
        <> 
        <Box height={1} id="header">
          <Container maxWidth="md">
            <Box className="sortOut"  mt={2} mb={2}>
                <Typography variant="h6">
                Comments
                </Typography>
                <Box className="recipe-likes" >
                    <Box className="userLike rightSP">
                    <AiOutlineHeart/> 
                    <Typography>{recipe.likecnt}</Typography>
                    </Box>
                    <Box className="userCmt noRightSP">
                    <RiChat3Line /> <Typography>{recipe.commentcnt}</Typography>
                    </Box>  
                </Box>
            </Box>
            <Box className="cmtBox" >
              <TextField
                className="cmtTextfild"
                type={"text"}
                placeholder="Add Comment"
                rows={4}
                multiline
                onChange={(e)=>onChange(e)}
                />
             <Button 
               className="sendbtn"
               fullWidth={false}
               variant="contained"
               color="primary"
               onClick={saveComment}
             >
              <SendIcon/>  
              Post
            </Button>
            </Box>
            {loader ? <Loader/> : null}
            {comments.length>0 ?
            comments.map((com,index) => (
            <Box className="cmtBox" >
                <Box className="cmtBoxTop" mb={1}>
                  <Box pr={1}>
                    <Avatar 
                    />
                  </Box>
                  <Box>
                    <Typography className="cmtUser">
                        @{com.uname}
                    </Typography>
                    <Typography className="cmtDate">
                        {com.createdAt}
                    </Typography>
                  </Box>
                </Box>
                <Box className="cmtBoxBott paddingLeft">
                {com.comment}
                </Box>
                {/* <Link className="linkText paddingLeft" key={index} onClick={handleToggle} >Reply</Link>   */}
                <SubComment isActive={false} subcom={com.subcomment} commentid={com.commentid} recipeid={recipe.id} profileid={profile.id}  onAddComment={setSubcomment}/>
                {/* <Box key={index} className={isActive ? "replyWrapper" : null} >
                    <Box className="showReply paddingLeft">
                        {com.subcomment.length>0 ?
                        com.subcomment.map((subcom) => (
                        <Box className="allcmts">
                            <Box className="singlerply">
                                <Box className="cmtBoxTop">
                                    <Box pr={1}>
                                        <Avatar 
                                        />
                                    </Box>
                                    <Box>
                                        <Typography className="cmtUser">
                                            @{subcom.uname}
                                        </Typography>
                                        <Typography className="cmtDate">
                                            Dec 02, 2021
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="cmtBoxBott">
                                {subcom.comment}
                                </Box>
                            </Box>
     
                        </Box>
                        )) : null}
                        <TextField
                        className="cmtTextfild"
                        type={"text"}
                        placeholder="Add Comment"
                        rows={3}
                        multiline
                        onChange={(e)=>onChange(e)}
                        />
                        <Button 
                        className="sendbtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        onClick={()=>saveSubComment(com.commentid)}
                        >
                        <SendIcon/>  
                        Post
                        </Button>  
                    </Box>
                </Box> */}
            </Box>
           )) : null}
          </Container>
        </Box>
        </>
    );

    
};

const mapStateToProps = (state) => {
    return {
    //   profile: state.auth.session.user,
      busy: state.busy,
      comments: state.recipe.comments,
      recipe: state.recipe.recipe,
      user: state.user.user,
      profile: state.auth.session.user,
      isAuthenticated: state.auth.isAuthenticated,
    };
};

// export default Comments;

const mapDispatchToProps = {
     showToast,
     getUserRecipesComments,
     getRecipe,
     addComment,
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Comments)
  );