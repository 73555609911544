import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./HookForm.scss";

function HookForm(props) {
    const { onSubmit, init } = props;

    const method = useForm({
        mode: "onChange",
        defaultValues: props.defaultForm || {},
        criteriaMode: "all",
    });

    useEffect(() => {
        init && init(method);
        // eslint-disable-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={method.handleSubmit(onSubmit)}>
            {props.children(method)}
        </form>
    );
}

HookForm.defaultProps = {
    defaultValues: {},
    onSubmit: () => {
        console.log("onSubmit function prop is not passed..!!");
    },
};
export default HookForm;
