import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const userRecipesReducer = createReducer([])({

    [types.USER_RECIPES_COMPLETED]: (state, action) => {
        const recipes = get(action, 'payload.data', {});
        console.log(recipes)
        return recipes;
    },
});


const userRecipesBinReducer = createReducer([])({

    [types.USER_RECIPES_BIN_COMPLETED]: (state, action) => {
        const recipesbin = get(action, 'payload.data', {});
       // console.log(recipes)
        return recipesbin;
    },
});

const getRecipeReducer = createReducer({})({

    [types.GET_RECIPE_COMPLETED]: (state, action) => {
        const recipe = get(action, 'payload.data', {});        
        return recipe;
    },
});

const getTagReducer = createReducer([])({

    [types.GET_TAGS_COMPLETED]: (state, action) => {
        console.log("action"+action);
        const tags = get(action, 'payload.data', {});
        console.log(tags);
        return tags;
    },
});

const getLockedRecipeReducer = createReducer([])({

    [types.USER_RECIPES_LOCKED_COMPLETED]: (state, action) => {
        console.log("action"+action);
        const recipelocked = get(action, 'payload.data', {});
        console.log(recipelocked);
        return recipelocked;
    },
});


const getRecipeCommentsReducer = createReducer([])({

    [types.USER_RECIPES_COMMENTS_COMPLETED]: (state, action) => {
        console.log("action"+action);
        const comments = get(action, 'payload.data', {});
        console.log(comments);
        return comments;
    },
});

export default combineReducers({

    recipes: userRecipesReducer,
    recipesbin: userRecipesBinReducer,
    recipe: getRecipeReducer,
    tags:getTagReducer,
    recipelocked:getLockedRecipeReducer,
    comments: getRecipeCommentsReducer
});
