import React, { useState } from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography } from "@material-ui/core";
import Message from "../../util/message";
import { confirmEmail } from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import "./ConfirmEmail.scss";
import {
  login,
  initializeSession,
  authenticated,
} from "../../state/ducks/auth/operations";


const CONFIRM_EMAIL_FORM = {
  code: {
    name: 'code',
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.CodeEmpty
      }
    }
  },
};

const ConfirmEmail = (props) => {
  const [confirmForm, setConfirmEmailForm] = useState(null);
  const email = get(props, 'location.state.email', '');
  const userName = get(props, 'location.state.userName', '');
  const password = get(props,'location.state.password','');
  const onFormSubmit = async () => {
    try {
      if (Object.keys(confirmForm.errors).length) return;
      const { code } = confirmForm.getValues();
      await props.confirmEmail({ email, code });
      props.showToast({
        message: 'You have successfully verified your email address.',
        type: 'success'
      })
      // props.history.push({
      //   pathname: '/login',
      //   state: { userName }
      // })

      //New code for auto login
      const response = await props.login({ email, password });
      const { refreshToken, isEmailVerified, user, idToken } = response.data;

      const tokens = { accessToken: idToken, refreshToken };
        props.initializeSession({ user, tokens });
        props.authenticated();
        if (userName) {
          props.history.push(`u/${userName}`);
        } else {
          props.history.push("/");
        }

      //Auto Login End

    } catch (err) {
      props.showToast({
        message: get(err, 'response.data.message', Message.ErrorMessage.API_ERROR),
        type: 'error'
      })
    }
  };

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box height={1 / 3} bgcolor="black" color="white" className="brand-cover-container">
        <Box p="1rem" color="white" className="cover-content">
           <Box className="appName">
              <span>
                <img src={mainLogo} alt="logo" />
              </span>
              <Typography variant="h5" color="inherit">
                Panfavorite
              </Typography>
           </Box>
        </Box>
        <Box className="brand-cover"></Box>
      </Box>
      <Box flex={1} p='1rem' className="bg-white">
        <Container maxWidth="sm">
          <HookForm
            defaultForm={{}}
            init={setConfirmEmailForm}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box display="flex" flexDirection="column" mt='2rem' height={1}>
                  <Box marginBottom="2rem" fontWeight="bold">
                    <Typography variant="h5" align="center" >
                      CONFIRM EMAIL
                    </Typography>
                  </Box>
                  <Box marginBottom="2rem">
                    <Typography align="center" className='gray-font'>
                      Please confirm your email by providing the code you have recieved on {`${email}`}
                    </Typography>
                  </Box>
                  <Box className='auth-card'>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={CONFIRM_EMAIL_FORM.code.validate}
                        name={CONFIRM_EMAIL_FORM.code.name}
                        errors={formMethod?.errors}
                        type={"text"}
                        placeholder="Code"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <CustomButton
                        type="submit"
                        title="CONFIRM"
                        disabled={!formMethod?.formState.isValid}
                        loading={!!props.busy}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </HookForm>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {
  confirmEmail,
  showToast,
  authenticated,
  initializeSession,
  login
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail));
