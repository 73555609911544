import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Card, Button } from "@material-ui/core";
import EditSpanTags from "../../components/EditSpanTags/EditSpanTags";
import { showToast } from "../../state/ducks/utils/operations";
import Message from "../../util/message";
import { connect } from "react-redux";
import { editRecipe } from "../../state/ducks/recipe/operations";
import { get } from "lodash";
import Loader from '../../loader';

import {
    useLocation,
} from "react-router-dom";

const EditTags = (props) => {

    const { profile, editRecipe, showToast, history } = props;
    const [tags1, setTags] = useState(localStorage.getItem('tagsedit') || localStorage.getItem('tags'));
    const [prevData, setPrevData] = useState([]);
    const [disable, setDisable] = React.useState(false);

    const [loader, setLoader] = React.useState(false);

    const location = useLocation();


    useEffect(() => {
        setPrevData(location.state);
        console.log(location.state);
        // setTags(location.state.arr1.tags);        
        
    }, [location]);

    var keys = [];
    const onFormSubmit = async () => {
        setDisable(true)
        setLoader(true);
        let tags = localStorage.getItem('tags');
        const arr = JSON.parse(tags);
        try {
        if(arr.length<3)
        {
            
            setTimeout(
                showToast({
                    message:Message.ErrorMessage.TagsMessage,
                    type: "error",
                  })  
                  ,2000);
                  setLoader(false);
            
        }
        else
        {
            console.log(prevData.arr1.paramId);
            const formData = new FormData();
            formData.append("userId", prevData.arr1.userId);
            formData.append("difficulty",prevData.arr1.difficulty);
            formData.append("coverPic", prevData.arr1.coverPic);
            formData.append("ingredients", prevData.arr1.ingredients);
            formData.append("instructions", prevData.arr1.instructions);
            formData.append("amounts", prevData.arr1.amounts);
            formData.append("isPinned",prevData.arr1.isPinned);
            formData.append("tags", localStorage.getItem('tags'));

            for (const key of Object.keys(prevData.arr1.key)) {
                if(key=='activeTime' && (prevData.arr1.key[key]=="" || prevData.arr1.key[key]==null))
                {
                    formData.append(key, 0);
                }
                else
                {
                    formData.append(key, prevData.arr1.key[key]);
                }
                
                keys[key]=prevData.arr1.key[key];
                // console.log(key);
              }

              await editRecipe({ id: prevData.arr1.paramId, form: formData });
              showToast({
                message: Message.SuccessMessage.RecipeUpdated,
                type: "success",
              });
              setLoader(false);
            history.push(`/u/${profile.userName}`);
        }
        } catch (err) {
            setLoader(false);
            showToast({
                message: get(
                  err,
                  "response.data.message",
                  Message.ErrorMessage.API_ERROR
                ),
                type: "error",
              });
        }
    }
    return (
        <Container maxWidth="md" className="pageContainer">
            <Box className="tagsOut">
                {loader ? <Loader/> : null} 
                <Typography variant="h6" className="sectHeading">
                    TAGS
                </Typography>
                {/* <Typography className="greyFont">
                    You had selected tags {JSON.stringify(tags1)} 
                </Typography> */}
                <Typography className="greyFont">
                    Select atleast 3 tags so your recipes easy to find!
                </Typography>
                <Typography className="tagsTitle">
                    Ingredients
                </Typography>
                <Box className="myTags">
                    <EditSpanTags isActive={Boolean(tags1.includes('Chicken'))} id='Chicken' content='Chicken'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Seafood'))} id='Seafood' content='Seafood'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Pork'))} id='Pork' content='Pork'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Beef'))} id='Beef' content='Beef'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Pasta'))} id='Pasta' content='Pasta'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Dairy'))} id='Dairy' content='Dairy'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Turkey'))} id='Turkey' content='Turkey'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Rice'))} id='Rice' content='Rice'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Tofu'))} id='Tofu' content='Tofu'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Seitan'))} id='Seitan' content='Seitan'></EditSpanTags>
                </Box>

                <Typography className="tagsTitle">
                    Dietary Restrictions
                </Typography>

                <Box className="myTags">
                    <EditSpanTags isActive={Boolean(tags1.includes('Gluten-Free'))} id='Gluten-Free' content='Gluten-Free'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Dairy-Free'))} id='Dairy-Free' content='Dairy-Free'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Vegitarian'))} id='Vegitarian' content='Vegitarian'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Vegan'))} id='Vegan' content='Vegan'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Healthy'))} id='Healthy' content='Healthy'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Low Fat'))} id='Low Fat' content='Low Fat'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Sugar-Free'))} id='Sugar-Free' content='Sugar-Free'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Kosher'))} id='Kosher' content='Kosher'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Low Calorie'))} id='Low Calorie' content='Low Calorie'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('High Protein'))} id='High Protein' content='High Protein'></EditSpanTags>
                </Box>

                <Typography className="tagsTitle">
                    Cuisine
                </Typography>
                <Box className="myTags">
                    <EditSpanTags isActive={Boolean(tags1.includes('Chinese'))} id='Chinese' content='Chinese'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Italian'))} id='Italian' content='Italian'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('French'))} id='French' content='French'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Indian'))} id='Indian' content='Indian'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Mediterranean'))} id='Mediterranean' content='Mediterranean'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Mexican'))} id='Mexican' content='Mexican'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Thai'))} id='Thai' content='Thai'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Vietnamese'))} id='Vietnamese' content='Vietnamese'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Spanish'))} id='Spanish' content='Spanish'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Japanese'))} id='Japanese' content='Japanese'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Fusion'))} id='Fusion' content='Fusion'></EditSpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Cooking Method
                </Typography>
                <Box className="myTags">
                    <EditSpanTags isActive={Boolean(tags1.includes('Slow Cooker'))} id='Slow Cooker' content='Slow Cooker'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Pressure Cooker'))} id='Pressure Cooker' content='Pressure Cooker'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Air Frier'))} id='Air Frier' content='Air Frier'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Grill'))} id='Grill' content='Grill'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Oven'))} id='Oven' content='Oven'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Stir Fry'))} id='Stir Fry' content='Stir Fry'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('One Pan'))} id='One Pan' content='One Pan'></EditSpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Miscellaneous
                </Typography>
                <Box className="myTags">
                    <EditSpanTags isActive={Boolean(tags1.includes('Breakfast'))} id='Breakfast' content='Breakfast'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Lunch'))} id='Lunch' content='Lunch'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Dinner'))} id='Dinner' content='Dinner'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Dessert'))} id='Dessert' content='Dessert'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Appetizers'))} id='Appetizers' content='Appetizers'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Sandwich'))} id='Sandwich' content='Sandwich'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Soup'))} id='Soup' content='Soup'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Salad'))} id='Salad' content='Salad'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Casseroles'))} id='Casseroles' content='Casseroles'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Smoothie'))} id='Smoothie' content='Smoothie'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Snack'))} id='Snack' content='Snack'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Beverage'))} id='Beverage' content='Beverage'></EditSpanTags>
                    <EditSpanTags isActive={Boolean(tags1.includes('Sauce'))} id='Sauce' content='Sauce'></EditSpanTags>
                </Box>
                <Button className="tagButton" disabled={disable} onClick={onFormSubmit}>
                    SAVE
                </Button>

            </Box>
        </Container>
    );

};

const mapStateToProps = (state) => {
    return {
        busy: state.busy,
        profile: state.auth.session.user,
    };
};

const mapDispatchToProps = { showToast, editRecipe };

export default connect(mapStateToProps, mapDispatchToProps)(EditTags);
