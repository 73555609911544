import React, { useEffect, useState, Typography,  } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Box, Button,Select, MenuItem, Modal, NativeSelect } from "@material-ui/core";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { RiChat3Line } from "react-icons/ri";
import Message from "../../util/message";
import Constant from "../../util/constant";
import {
  updateCoverImage,
  updateProfileImage,
  updateProfile,
} from "../../state/ducks/auth/operations";
import { getUserRecipes,likeRecipe,getTopRecipeTags } from "../../state/ducks/recipe/operations";
import {
  getUserById,
  getUserByUserName,
} from "../../state/ducks/user/operations";
import { showToast } from "../../state/ducks/utils/operations";

import Avatar from "../../components/Avatar/Avatar";
import Dialog from "../../components/Dialog/Dialog";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import CoverUploader from "../../components/CoverUploader/CoverUploader";
import EditProfileDialog from "../../components/EditProfileDialog/EditProfileDialog";
import { IoIosAddCircleOutline } from "react-icons/io";
import CloseIcon from '@material-ui/icons/Close';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import Loader from '../../loader';


import "./EditProfile.scss";

const EditProfile = (props) => {

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const { profile, getUserRecipes, recipes, history,getTopRecipeTags, tags } = props;
  const [editMode, setEditMode] = useState(false);
  const [coverPic, setCoverPic] = useState();
  const [coverFile, setCoverFile] = useState();
  const [profilePic, setProfilePic] = useState();
  const [profileFile, setProfileFile] = useState();
  const [offset,setOffset]=useState(10);
  const [sortby,setSortBy]=useState("");
  const [filterby,setFilterBy]=useState("");
  const [loader, setLoader] = React.useState(false);
 
  const ITEM_HEIGHT = 40;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setFilterBy(e);
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };
  
  const handleChangeFilter = (value) => {
    if(filterby==value)
    {
      setFilterBy(''); 
    }
    else
    {
      setFilterBy(value); 
    }
    
    setOpenModal(false);
  };

  const onScroll = (e) => {
    
      setLoader(true);
           setOffset(()=>{
              return offset+10;
    });
  }

  const loading=(offset)=>{
   
    setTimeout(()=>{
      setLoader(false);
    },2800)
    
  }

  useEffect(() => {
    // window.addEventListener('scroll', onScroll,true);
    getTopRecipeTags(profile.id);
    if(sortby=="")
    {
        if(filterby=="")
        getUserRecipes(profile.id,'createdAt','All',profile.id,offset);
        else
        getUserRecipes(profile.id,'createdAt',filterby,profile.id,offset);

        loading(offset)
    }
    else
    {
        if(filterby=="")
        getUserRecipes(profile.id,sortby,'All',profile.id,offset);
        else
        getUserRecipes(profile.id,sortby,filterby,profile.id,offset);

        loading(offset)
    }

    // return () => {
    //   window.removeEventListener('scroll', onScroll,true);
    // }; 
    
  }, [sortby,filterby,offset]);

  const applyCoverChange = async () => {
    try {
      const form = new FormData();
      form.append("coverPic", coverFile);
      await props.updateCoverImage({ id: profile.id, form });
      props.showToast({
        message: Message.SuccessMessage.CoverPicUpdate,
        type: "success",
      });
    } catch (err) {
      props.showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
    clearCoverData();
  };

  const onCoverChange = (file) => {
    setCoverFile(file);
    var fr = new FileReader();
    fr.onload = () => setCoverPic(fr.result);
    fr.readAsDataURL(file);
  };

  const clearCoverData = () => {
    setCoverPic(null);
    setCoverFile(null);
  };

  const applyProfileChange = async () => {
    try {
      const form = new FormData();
      form.append("profilePic", profileFile);
      await props.updateProfileImage({ id: profile.id, form });
      props.showToast({
        message: Message.SuccessMessage.ProfilePicUpdate,
        type: "success",
      });
    } catch (err) {
      props.showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
    clearProfileData();
  };

  const onProfileChange = (file) => {
    setProfileFile(file);
    var fr = new FileReader();
    fr.onload = () => setProfilePic(fr.result);
    fr.readAsDataURL(file);
  };

  const clearProfileData = () => {
    setProfilePic(null);
    setProfileFile(null);
  };

  const showEditProfile = () => setEditMode(true);

  const hideEditProfile = () => setEditMode(false);

  const onSaveProfile = async ({
    name,
    userName,
    bio,
    subscriptionFee,
    city,
    state,
    external_links,
  }) => {
    try {
      const data = { name, userName, bio, city, state,external_links };

      if (subscriptionFee) data.subscriptionFee = +subscriptionFee;

      await props.updateProfile({ id: profile.id, ...data });
      props.showToast({
        message: Message.SuccessMessage.CoverPicUpdate,
        type: "success",
      });
      setEditMode(false);
      history.push(`/u/${userName}`);
    } catch (err) {
      props.showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const redirectToAddRecipe = () => {
    props.history.push("/recipe");
  };

  const redirectToRecipe = (id) => {
    props.history.push(`/recipe/${id}`);
  };

  const redirectToBilling = () => {
    props.history.push({
      pathname: "/account",
      state: { tab: "BANKING INFO" },
    });
  };

  const onLikeClick=async(flag,recipeid,userid)=>{
    console.log(flag)
    let likeflag;
    
    if(flag=='nolike')
    {
        likeflag=true;
    }
    else
    {
        likeflag=false;
    }

    try {
      const formData = new FormData();
      formData.append("recipeid", recipeid);
      formData.append("userid",userid);
      formData.append("is_liked", likeflag);
      await likeRecipe(formData);
      showToast({
        message: Message.SuccessMessage.RecipeLiked,
        type: "success",
        });
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  }

  const openComment=async(props,recipe_id)=>{
    props.history.push(`/comments/${recipe_id}`);
  }
  const handleCloseOut = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box height={1}>
        <Box
          className="cover-banner"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          position="relative"
        >
          <Container maxWidth="md" className="myProfile">
            <Box className="profileOut">
              <Box position="absolute" bottom={"-50%"} zIndex={1}>
                <Avatar
                  pic={profile.profilePic}
                  name={profile.name}
                  onChange={onProfileChange}
                />
              </Box>
            </Box>
            <Box zIndex={1}>
              <CoverUploader onChange={onCoverChange} />
            </Box>
          </Container>
          <Box position="absolute" height={1} width={1}>
            <img
              className="cover-img"
              alt={profile.name}
              src={
                profile.coverPic
                  ? `${Constant.COVER_BUCKET_PATH}/${
                      profile.coverPic
                    }?timestamp=${Date.now()}`
                  : `${Constant.DEFAULT_IMAGE_PATH}/${
                      Constant.DEFAULT_RECIPE_IMAGE
                    }?timestamp=${Date.now()}`
              }
            />
          </Box>
        </Box>
        <Container maxWidth="md" className="pageContainer">
          <Box flex={1} mt={12} className="firstCard">
            <ProfileInfo user={profile} onEditClick={showEditProfile} />
          </Box>
          {profile.account ? null : (
            <Box mt={4} className="TopSpace">
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={() => redirectToBilling()}
              >
                BILLING SETUP
              </Button>
            </Box>
          )}
          <Box mt={3} textAlign="center" className="TopSpace">
            <Button
              variant="contained"
              className="addNewRecp"
              fullWidth
              onClick={redirectToAddRecipe}
            >
              <Box p={2}>
                <IoIosAddCircleOutline /> ADD NEW RECIPE
              </Box>
            </Button>
          </Box>
          <Box className="sortOut"  mt={2} mb={2}>
          <h6>
                  My Recipes
          </h6>
          {loader ? <Loader/> : null}
          <div className="fiterOut">
            <NativeSelect className="mySelect"
              displayEmpty
              value={sortby} 
                onChange={handleChange}>
                <option value="" disabled>Sort</option>
                <option value="createdAt">Recent</option>
                <option value="likelength">Popular</option>
                <option value="cookingTime">Cook Time</option>
                <option value="difficulty">Difficulty</option>
                <option value="name">A to Z</option>
            </NativeSelect>
            <Box className="mySelectRight" onClick={handleOpenModal}>
              <SortRoundedIcon/>
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
            <div className="filterModal">
              <h5>Filters</h5>
              <Box className="filterTags">
              { tags.length>0 ?
                  tags.map((option) => (
                    <span className={filterby==option ? 'selectedTag' : ''} key={option} onClick={(e)=>handleChangeFilter(option)}>
                      {option}
                    </span>
                  )) : ''}

              </Box>
              <Box className="modalClose" onClick={handleCloseModal}>
                  <CloseIcon/>
              </Box>
            </div>
            </Modal>
            {/* <NativeSelect className="mySelect" displayEmpty onChange={handleChangeFilter} value={filterby} >
                <option  value="" disabled>Filter</option >
                { tags.length>0 ?
                  tags.map((option) => (
                    <option key={option} onClick={(e)=>handleClose(option)}>
                      {option}
                    </option>
                  )) : ''}
            </NativeSelect> */}
            </div>
          </Box>

   
  

          {/* {renderRecipes(props)} */}

          {
          recipes.length>0 ?
          (
          recipes.map((recipe, index) => (
            <Box className="proLikeOut">
            <Box mt={2} onClick={() => redirectToRecipe(recipe.id)} key={index}>
              <RecipeCard
                key={recipe.id}
                name={recipe.name}
                isPinned={recipe.isPinned}
                cookingTime={`${recipe.cookingTime} min`}
                likecnt={`${recipe.likelength}`}
                coverPic={
                  recipe.coverPic
                    ? `${Constant.RECIPE_BUCKET_PATH}/${
                        recipe.coverPic
                      }?timestamp=${Date.now()}`
                    : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
                }
              />
            </Box>
            <Box className="recipe-likes recipeLikesProfile">
                <Box className="userLike">
                  <Button>
                    <AiFillHeart/>
                    <span>{recipe.likelength}</span>
                  </Button>
                  {/* <Button> 
                    <AiOutlineHeart /> 
                    <span>23</span>
                  </Button> */}
                </Box>
                <Box className="userCmt">
                <Button onClick={()=>openComment(props,recipe.id)}>
                  <RiChat3Line /> <span>{recipe.comcount}</span>
                </Button>  
                </Box>  
            </Box>
            </Box>
          ))
          ) : null
        }
          {recipes.length>0 ?
          recipes.length!==recipes[0].totrecipe && 
          <Box mt={2} pb={2}><Button color="primary" variant="contained" onClick={onScroll}>Load more</Button></Box>
          :null}

          {/* <Box mt={4}>
            <Typography align="left" variant="h6">
              Add Subscription Bullets
            </Typography>
            <Box mt={1}>
              <TextField
                multiline
                fullWidth={true}
                variant="outlined"
                rows={2}
                value={1}
              />
            </Box>
            <Box mt={1}>
              <TextField
                multiline
                fullWidth={true}
                variant="outlined"
                rows={2}
                value={2}
              />
            </Box>
            <Box mt={1}>
              <TextField
                multiline
                fullWidth={true}
                variant="outlined"
                rows={2}
                value={3}
              />
            </Box>
          </Box>
          <Box mt={4} textAlign="center">
            <Button variant="contained" color="primary">
              + ADD MORE
            </Button>
          </Box>
           */}
        </Container>
      </Box>
      <EditProfileDialog
        open={!!editMode}
        user={profile}
        userName={profile.userName}
        history={ history}
        onCancel={hideEditProfile}
        onSave={onSaveProfile}
      />
      <Dialog
        className="imgWidth"
        open={!!coverPic}
        title="COVER IMAGE"
        confirmText="APPLY"
        cancelText="CANCEL"
        onCancel={clearCoverData}
        onConfirm={applyCoverChange}
      >
        <img src={coverPic} alt={profile.name}></img>
      </Dialog>
      <Dialog
        open={!!profilePic}
        title="PROFILE IMAGE"
        confirmText="APPLY"
        cancelText="CANCEL"
        onCancel={clearProfileData}
        onConfirm={applyProfileChange}
      >
        <img src={profilePic} alt={profile.name}></img>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
    recipes: state.recipe.recipes,
    user: state.user.user,
    tags: state.recipe.tags
  };
};

const mapDispatchToProps = {
  updateCoverImage,
  updateProfileImage,
  showToast,
  updateProfile,
  getUserRecipes,
  getTopRecipeTags,
 
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProfile)
);
