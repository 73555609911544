import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Container, Box, Typography,Card } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";

import { showToast } from "../../state/ducks/utils/operations";

import "./Checkout.scss";
import CustomButton from "../../components/CustomButton/CustomButton";
import { subRecurr } from "../../state/ducks/subscribe/actions";

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const Checkout = (props) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [paymentData, setPaymentData] = useState("");
  const [date1,setDate1]=useState();
  const [amount, setAmount] = useState();
  const [freetrial, setFreeTrial] = useState();
  const [chef_prod_price_id, setPriceId] = useState();
  const [stripe_customer_id, setCustomerId] = useState();
  const [name, setName] = useState("");
  const [connectedAccount, setConnectedAccount] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const {
    user,
    profile,
    subRecurr,
  } = props;


  useEffect(() => {
    const { amount, name, free_trial,chef_prod_price_id, ...payment } = props.location.state;
    console.log("data> "+JSON.stringify(payment));
    setName(name)
    setAmount(amount);
    setPaymentData(payment);
    setFreeTrial(free_trial);
    setPriceId(chef_prod_price_id);
    setConnectedAccount(payment.connectedAccount);
    setCustomerId(payment.stripe_customer_id);

    //alert(payment.payment_method);
    if(payment.card_details_flag=='Y')
    {
  //    alert("in if");
      setError("");
      setDisabled(false);
    }
    var date = new Date(); // Now
    var unix= date.setDate(date.getDate() + 30);
     var s = new Date(unix).toLocaleDateString("en-US")
    setDate1(s)
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


  const handleSubmit = async (ev) => {
    try {

      // console.log("card : "+elements.getElement(CardElement));
      // return false;
      ev.preventDefault();
      setProcessing(true);
      let stripeAccount=paymentData.stripeAccount;
      var payment_method=paymentData.payment_method;

      if(paymentData.clientSecret!==null)
      {
        const result=await stripe.confirmCardSetup(
          paymentData.clientSecret, 
          {
            payment_method: {
              card:elements.getElement(CardElement),
            },
          },
         );
         if(result.error)
         {
          
            payment_method=result.error.setup_intent.payment_method;
         }
         else if(result.setupIntent)
         {
          
              payment_method=result.setupIntent.payment_method;
         }
         else
         {
            console.log("result else"+JSON.stringify(result));
         }
      
      }
     
        let response=await subRecurr({'payment_method':payment_method,'email':profile.email,'name':profile.name,'metadata':paymentData.metadata,'stripe_customer_id':stripe_customer_id,'userid':profile.id,'freetrial':freetrial,'chef_prod_price_id':chef_prod_price_id,'stripeAccount':paymentData.stripeAccount,'connectedAccount':connectedAccount});

        const {client_secret, status} = response.data;

        console.log(JSON.stringify(response));

        
        
        if (status === 'requires_action') {
          stripe.confirmCardPayment(client_secret,{
            payment_method: {
              card:elements.getElement(CardElement),
                  billing_details: {
                  email: profile.email,
                },
            }
          }).then(function(result) {
            if (result.error) {
              console.log('There was an issue!');
              console.log(result.error);
              setError(`Payment failed ${result.error}`);
              setProcessing(false);
              
            } else {
              console.log('You got the money!');
              setError(null);
              setProcessing(false);

            
              props.history.push('/')
              props.showToast({
                message: 'Subscribed successfully.',
                type: 'success'
              })
              
            }
          });
        } else {
          //console.log('You got the money!');
          setError(null);
          setProcessing(false);

        
          props.history.push('/')
          props.showToast({
            message: 'Subscribed successfully.',
            type: 'success'
          })
         
        }

    } catch (err) {
      setError(err.message);
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md">
       <Box mt={3}>  
        <Card className="flexDiv">
          <Typography variant="h6">TOTAL DUE TODAY</Typography>
          <Typography variant="h6">${amount+0.32} </Typography>
        </Card>
      </Box>

      <Box mt={2}>  
        <Card className="totalDiv">
          <Typography variant="h6"  >YOUR SUBSCRIPTION</Typography> 
         
          <Box className="totalDivFlex">
              <Box>
                <Typography>{name}'s Premium Recipe Page</Typography>
              </Box>
              <Box className="totalRight">
                <Typography>${amount}/mo</Typography>
              </Box>
          </Box>

          <Box className="totalDivFlex">
              <Box>
                <Typography>Additional Processing Fee</Typography>
              </Box>
              <Box className="totalRight">
                <Typography>$0.32/mo</Typography>
              </Box>
          </Box>

          <Box className="totalDivFlex">
              <Box>
                <Typography>Total Subscription Fee</Typography>
              </Box>
              <Box className="totalRight">
                <Typography>${amount+0.32}/mo</Typography>
              </Box>
          </Box>
        </Card>
      </Box>

      
      <Box mt={2}>
        <form className="checkout-form" onSubmit={handleSubmit}>
          {/* paymentData.clientSecret!==null || */}
        { paymentData.card_details_flag=='N' &&
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
        }
          {/* Show any error that happens when processing the payment */}
          {error && (
            <Box className="card-error" role="alert" my={2}>
              <Typography>{error}</Typography>
            </Box>
          )}
          
          <CustomButton
            disabled={error ||processing || disabled}
            loading={processing}
            id="submit"
            type="button"
            color="primary"
            onClick={handleSubmit}
          >
            PAY ${amount+0.32}
          </CustomButton>
        </form>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    user: state.user.user,
    profile: state.auth.session.user,
  };
};

const mapDispatchToProps = {
  showToast,
  subRecurr
};

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Checkout)
// );


const CheckoutWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);

const StripeWrapper = (props) => {
  const [stripe, setStripe] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const publishableKey = get(props, 'location.state.publishableKey', '');
    const stripeAccount = get(props, 'location.state.stripeAccount', '');
    if (!publishableKey || !stripeAccount) {
      setLoading(false);
      setError("Unable to proccess payment please go back");
    } else {
      console.log("stripeacc"+stripeAccount)
      loadStripe(publishableKey, { stripeAccount }).then((stripe) => {
        setStripe(stripe);
        setLoading(false);
      }).catch(err => {
        setError(err.message);
      });
    }
  }, []);

  return (
    <Box className="pageContainer">
      {error ? <Box>{error}</Box> : null}
      {loading ? <Box>Loading...</Box> : null}
      {stripe ? (
        <Elements stripe={stripe} options={ELEMENTS_OPTIONS}>
          <CheckoutWrapper />
        </Elements>
      ) : null}
    </Box>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps) (StripeWrapper)
);
