export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

export const SELECT_MENU = "SELECT_MENU";

export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_FAILED = "CONTACT_US_FAILED";
export const CONTACT_US_COMPLETED = "CONTACT_US_COMPLETED";