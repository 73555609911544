import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { cloneDeep } from 'lodash';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { showToast } from "../../state/ducks/utils/operations";

const GroupInput = ({ title, multiline, rows, onChange, onAmount, defaulValue = Array(3).fill(""),flag}) => {
  const [inputs, setInputs] = useState(defaulValue);
  const [amount, setAmounts] = useState(defaulValue);
  let arr=[];
  let arramt=[];
  defaulValue.map(input=>{
    arr.push(input.name);
    arramt.push(input.amounts);
    
})

  const onInputChange = (e, index) => {
    
    let ref=[];
    if(flag=="edit")
    {
      ref =cloneDeep(arr);
      defaulValue[index].name=e.target.value;
    }
    else
    {
      ref = cloneDeep(inputs);
    }
    
    ref[index] = e.target.value;
    
    // console.log("ref>> "+ref+" index>> "+index);
    setInputs(ref);
    onChange && onChange(ref) //.filter(i => !!i)
  };

  const onInputChangeAmount = (e, index) => {
    //const ref = cloneDeep(amount);
    let ref=[];
    if(flag=="edit")
    {
      ref =cloneDeep(arramt);
      defaulValue[index].amounts=e.target.value;
    }
    else
    {
      ref = cloneDeep(amount);
    }
    
    ref[index] = e.target.value;
    
   
    setAmounts(ref);
    onAmount && onAmount(ref) //.filter(i => !!i)
  };

  
  const onAddClick = () => {
    let ref=[];
    if(flag=="edit")
    {
      ref =cloneDeep(arr);
      console.log(arr);
      defaulValue.push({'name':'','amounts':''});
      console.log(defaulValue);
    }
    else
    {
      ref = cloneDeep(inputs);
      ref.push('');
    }
    
    setInputs(ref);
  };

  const onRemoveClick = (index) => {
    let ref=[];
    let refamt=[];
    if(inputs.length === 1) return
    if(flag=="edit")
    {
      ref =cloneDeep(arr);
      refamt=cloneDeep(arramt);
      ref.splice(index, 1)
      setInputs(ref);
      refamt.splice(index, 1)
      setAmounts(refamt);

      defaulValue.splice(index,1);
      
      onChange && onChange(ref)
      onAmount && onAmount(refamt)
    }
    else
    {
      ref = cloneDeep(inputs);
      ref.splice(index, 1)
    setInputs(ref);
    }
    
    
  };

  useEffect(() => {
    setInputs(defaulValue || Array(3).fill(""))
    setAmounts(defaulValue || Array(3).fill(""))
  }, [`${defaulValue}`])

  return (
    <>
      <Box mt={2}>
        <Typography align="left" variant="h6">
          {title}
        </Typography>
        {
        title=='Ingredients' ?
        inputs.map((input, index) => (
          <Box mt={1} display="flex" key={index} className="addIngredient">
             <TextField 
              className="ingrediAmt"
              variant="outlined"
              placeholder="Amount"
              rows={rows}
              defaultValue={input.amounts}
              onChange={(e) => onInputChangeAmount(e, index)}
            />
            <TextField 
              className="ingrediName"
              variant="outlined"
              placeholder="Ingredient"
              multiline={multiline}
              rows={rows}
              defaultValue={input.name}
              onChange={(e) => onInputChange(e,index)}
            />
            <IconButton onClick={() => onRemoveClick(index)} disabled={!index}>
              <RemoveCircleOutlineIcon/>
            </IconButton >
          </Box>
        ))
      :
      inputs.map((input, index) => (
        <Box mt={1} display="flex" key={index} className="addIngredient">
          <TextField 
            fullWidth={true}
            variant="outlined"
            placeholder={(index + 1).toString()}
            multiline={multiline}
            rows={rows}
            value={input}
            onChange={(e) => onInputChange(e, index)}
          />
          <IconButton onClick={() => onRemoveClick(index)} disabled={!index}>
            <RemoveCircleOutlineIcon/>
          </IconButton >
        </Box>
      ))
      }
      </Box>
      <Box mt={2} textAlign="center">
        <Button variant="outlined" color="primary" onClick={onAddClick}>
          + ADD MORE
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

export default connect(mapStateToProps, mapDispatchToProps)(GroupInput);
