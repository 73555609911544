import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { cloneDeep } from 'lodash';
import Switch from '@material-ui/core/Switch';
import { showToast } from "../../state/ducks/utils/operations";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const GroupLinks = ({ onChange,onError, defaulValue = Array(1).fill({'name':'','url':'','checked':true})}) => {
  const [inputs, setInputs] = useState(defaulValue);
  const [error,setError]=useState("");
  const [indexval,SetIndexVal]=useState(0);
  const urlPatternValidation = URL => {
    const regex = new RegExp('^https?:\\/\\/' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i');    
    return regex.test(URL);
  };

const onInputChange = (e, index,keyname) => {
     const ref = cloneDeep(inputs);

     if(keyname=='name')
     {
        ref[index].name=e.target.value;
     }

     if(keyname=='url')
     {
        ref[index].url=e.target.value;
      
        if(e.target.value!='')
        {
          const isTrueVal = urlPatternValidation(e.target.value);
          console.log(isTrueVal);
          if(isTrueVal==false)
          {
            setError("Please enter a valid URL");
            SetIndexVal(index);
          }
          else
          {
            setError("");
            SetIndexVal(0);
          }
        }
        else
        {
            setError("");
            SetIndexVal(0);
        }
       
     }
     
     if(keyname=='display')
     {
        ref[index].checked=e.target.checked;
        // setChecked(e.target.checked);
     }

     setInputs(ref);
    // console.log("ref>>" +JSON.stringify(ref));
     onError && onError(error);
     onChange && onChange(ref) //.filter(i => !!i)
  };

 
  const onAddClick = () => {
      let ref=[];
      ref = cloneDeep(inputs);
      ref.push({'name':'','url':'','checked':true});
      setInputs(ref);
  };

  const onRemoveClick = (index) => {
    let ref=[];
    if(inputs.length === 1) return
    ref = cloneDeep(inputs);
    ref.splice(index, 1)
    setInputs(ref);   
    onChange && onChange(ref)
  };

  useEffect(() => {
    setInputs(defaulValue || Array(1).fill({'name':'','url':'','checked':true}))
    
  }, [`${defaulValue}`])

 

  return (
    <>
    {inputs.map((input, index) => (
            <Box className="greyBox" key={index}>
                  <Box className="greyBoxTop">
                      <Switch
                        checked={input.checked}
                        onChange={(e) => onInputChange(e,index,'display')}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                  </Box>
                  <TextField
                    name="linkname" 
                    type={"text"}
                    defaultValue={input.name} 
                    placeholder="Title" 
                    onChange={(e) => onInputChange(e,index,'name')}
                  />
                  <TextField
                    name="linkurl"
                    type={"url"}
                    defaultValue={input.url}
                    placeholder="Enter URL Like http://google.com" 
                    onChange={(e) => onInputChange(e,index,'url')}
                   
                  />
                  { indexval==index &&
                  <div style={{ color: "#F61C04" }}>{error}</div>
                  }
                  <IconButton onClick={() => onRemoveClick(index)} disabled={!index}>
                    <HighlightOffIcon/>
                   </IconButton >
            </Box>
        ))}
                <Box mt={2} textAlign="center">
                  <Button variant="outlined" color="primary" onClick={onAddClick}>
                    + ADD MORE
                  </Button>
                </Box>
            </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

export default connect(mapStateToProps, mapDispatchToProps)(GroupLinks);
