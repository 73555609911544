import * as types from "./types";

export const getUserRecipes = (id,sort,filterby,loginid,offset) => ({
  type: types.USER_RECIPES,
  meta: {
    async: true,
    blocking: true,
    path: `user/${id}/recipe/${sort}/${filterby}/${loginid}/${offset}`,
    method: "GET",
  },
});


export const getUserLockedRecipes = (id) => ({
  type: types.USER_RECIPES_LOCKED,
  meta: {
    async: true,
    blocking: true,
    path: `user/${id}/lockedRecipe`,
    method: "GET",
  },
});


export const getUserRecipesComments = (id) => ({
  type: types.USER_RECIPES_COMMENTS,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/viewComment/${id}`,
    method: "GET",
  },
});

export const getUserRecipesBin = (id,sort,filterby,offset) => ({
  type: types.USER_RECIPES_BIN,
  meta: {
    async: true,
    blocking: true,
    path: `user/${id}/recipebin/${sort}/${filterby}/${offset}`,
    method: "GET",
  },
});

export const getTopRecipeTags = (userid) => ({
  type: types.GET_TAGS,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/${userid}`,
    method: "GET",
  },
});

export const getTopRecipeTagsBin = (userid) => ({
  type: types.GET_TAGS,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/${userid}/top/bin`,
    method: "GET",
  },
});

export const getRecipe = (id,userid) => ({
  type: types.GET_RECIPE,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/${id}/${userid}`,
    method: "GET",
  },
});

export const addRecipe = (recipe) => ({
  type: types.ADD_RECIPE,
  meta: {
    async: true,
    blocking: true,
    path: `recipe`,
    method: "POST",
    body: recipe,
  },
});


export const addComment = (recipe) => ({
  type: types.ADD_COMMENT,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/addComment`,
    method: "POST",
    body: recipe,
  },
});

export const likeRecipe = (data) => ({
  type: types.LIKE_RECIPE,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/likes`,
    method: "POST",
    body: data,
  },
});

export const editRecipe = ({ id, form }) => ({
  type: types.EDIT_RECIPE,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/${id}`,
    method: "POST",
    body: form,
  },
});

export const deleteRecipe = (id) => ({
  type: types.DELETE_RECIPE,
  meta: {
    async: true,
    blocking: true,
    path: `recipe/${id}`,
    method: "DELETE",
  },
});
