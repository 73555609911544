const Message = {
  ErrorMessage: {
    EmailEmpty: "Email address cannot be empty",
    EmailInvalid: "Are you sure you entered the valid email address?",
    PasswordEmpty: "Password cannot be empty.",
    PasswordInvalid: "Are you sure you entered the valid email?",
    ConfirmPassword: "Confirm password cannot be empty.",
    CurrentPassword: "Current password is required.",
    ConfirmPasswordInvalid: "Both passwords must match.",
    ChangePassword: "Current password cannot be empty.",
    NewPassword: "New password cannot be empty.",
    NewPasswordInvalid: "Both passwords must match.",
    AlphanumericInvalid:
      'This field can only contain "Alphanumeric,  Dot, Coma, and Hyphen" values.',
    SubscriptionFeeInvalid:'subscription price must be atleast $2',
    SubscriptionsNotAllowed:'This chef is no longer accepting new subscribers.',
    NameEmpty: "Name cannot be empty.",
    API_ERROR: "Something went wrong please try after sometime.",
    GiftCodeEmpty: "Gift code cannot be empty",
    CodeEmpty: "Code is required",
    CoverPicType: "Only .png/.jpeg/,jpeg files are allowed",
    ProfilePicType: "Only .png/.jpeg/,jpeg files are allowed",
    UserNameEmpty: "User name cannot be empty.",
    CookingTimeEmpty: "Cooking time is required",
    Difficulty: "Difficulty is required",
    CookingTimeNumber: "Cooking time should be a valid number",
    ServingsEmpty: "Servings is required",
    ServingsNumber: "Servings should be a number",
    DescritionEmpty: "Description is required",
    MessageEmpty: "Message is required",
    InvalidPhone: "Invalid phone number",
    InvalidUrl:"Invalid URL Format",
    TagsMessage: "Please Select Atleast 3 Tags",
    DifficultyMessage: "Please Select Difficulty",
  },
  SuccessMessage: {
    CoverPicUpdate: "Profile updated successfully",
    ProfilePicUpdate: "Profile pic updated successfully",
    RecipeCreated: 'Recipe created successfully',
    CommentCreated: 'Comment saved successfully',
    RecipeUpdated: 'Recipe updated successfully',
    ContactUs: 'Message sent successfully',
    UploadProgress:'Recipe upload in process. Please wait.',
    RecipeLiked: 'Recipe saved to favorites bin!',
    RecipeUnLiked:'Recipe removed from favorites bin'
  },
};

export default Message;
