import React, { useState } from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Box,
  Link,
  Typography
} from "@material-ui/core";

import Message from "../../util/message";
import Constant from "../../util/constant";
import { signup } from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";

import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import "./Signup.scss";


const SIGNUP_FORM = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  password: {
    name: 'password',
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.PasswordEmpty
      },
      validate: {
        length: (value) => (value && value.length >= 8) || 'Minimum 8 characters required',
        hasSpecialChar: (value) => (value && value.match(Constant.REGEX.SPECIAL_CHAR)) || 'Minimum 1 special character required',
        hasNumbers: (value) => (value && value.match(Constant.REGEX.CONTAIN_NUMBER)) || 'Minimum 1 number required',
        hasLowerUpper: (value) => (value && value.match(Constant.REGEX.LOWERCASE_UPPERCASE)) || 'Minimum upper and lower case character required',
      },
    }
  },
  name: {
    name: 'name',
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty
      }, pattern: {
        value: Constant.REGEX.Alphanumeric,
        message: Message.ErrorMessage.AlphanumericInvalid
      }
    },
  },
};

const Signup = (props) => {
  
  const history = useHistory();
  const [signupForm, setSignupForm] = useState(null);
  const userName = get(props, 'location.state.userName', '');
  const onFormSubmit = async () => {
    try {
      if (Object.keys(signupForm.errors).length) return;
      const values = signupForm.getValues();
     // console.log(values.password);
     const password=values.password;
      await props.signup(values);
      props.history.push({
        pathname: '/confirm-email',
        state: {
          email: values.email,
          userName,
          password:password
        }
      })
    } catch (err) {
      props.showToast({
        message: get(err, 'response.data.message', Message.ErrorMessage.API_ERROR),
        type: 'error'
      })
    }
  };

  const redirectToLogin = () => {
    const navState = userName ? { userName } : {}
    props.history.push({
      pathname: "/login",
      state: navState,
    });

  }

  return (
    <Box height={1} display="flex" flexDirection="column" className="overFlow">
      <Box bgcolor="black" color="white" className="brand-cover-container loginTop">
        <Box p="1rem" color="white" className="cover-content">
           <Box className="appName cursor" onClick={()=>history.push("/welcome")}>
             <Box className="topButton">
                  <span>
                    <img src={mainLogo} alt="logo" />
                  </span>
                  <Typography variant="h5" color="inherit">
                    Panfavorite
                  </Typography>
              </Box>
           </Box>
        </Box>
        <Box className="brand-cover marBottom0"></Box>
        <Typography variant="h3" className="pageName" >Sign Up</Typography>
      </Box>
      <Box flex={1} className="bglight loginBotton">
        <Container maxWidth="sm">
          <HookForm
            defaultForm={{}}
            init={setSignupForm}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box display="flex" flexDirection="column" alignItems="center" mt='2rem' height={1}>
                  <Box className='auth-card'>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={SIGNUP_FORM.name.validate}
                        name={SIGNUP_FORM.name.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder="Name"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={SIGNUP_FORM.email.validate}
                        name={SIGNUP_FORM.email.name}
                        errors={formMethod?.errors}
                        type="text"
                        placeholder="Email Address"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={SIGNUP_FORM.password.validate}
                        name={SIGNUP_FORM.password.name}
                        errors={formMethod?.errors}
                        type={"password"}
                        placeholder="Password"
                      />
                    </Box>
                    <Box className="loginOut noGoogleBt">
                      <CustomButton
                        type="submit"
                        title="GET STARTED"
                        disabled={!formMethod?.formState.isValid}
                        loading={!!props.busy}
                      />
                    </Box>
                    {/* <Box className="marBottom0" mt={3} >
                      <CustomButton
                        color="secondary"
                        variant="outlined"
                        type="submit"
                        title="SIGN UP WITH GOOGLE" 
                      />
                    </Box> */}
                    <Typography className="termsText">
                    By clicking the ‘GET STARTED’ button, you agree to the terms described
in <a target="_blank" href="terms&cons">PanFavorite’s Terms and Conditions</a> and in <a target="_blank" href="PrivacyPolicy">PanFavorite’s Privacy Policy</a>.
Read the notice before proceeding. If you do not agree, please do not ‘Get Started’.
                    </Typography>
                  </Box>
                  <Box
                    className="regiLink"
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                    width={1}
                    color="black"
                  >
                    <Link onClick={redirectToLogin}  >
                      <Typography className='black-font login-link'>Already registered? Login here</Typography>
                    </Link>
                  </Box>
                </Box>
              );
            }}
          </HookForm>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {
  signup,
  showToast
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
