import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Box, TextField as MaterialTextField,Typography,Button } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Message from "../../util/message";
import Constant from "../../util/constant";
import STATES from "../../util/state";

import HookForm from "../HookForm/HookForm";
import TextField from "../TextField/TextField";
import CustomButton from "../CustomButton/CustomButton";
import GroupLinks from "../GroupLinks/GroupLinks";

const EDIT_PROFILE_FORM = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
      pattern: {
        value: Constant.REGEX.Alphanumeric,
        message: Message.ErrorMessage.AlphanumericInvalid,
      },
    },
  },
  userName: {
    name: "userName",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.UserNameEmpty,
      },
    },
  },
  bio: {
    name: "bio",
    validate: {
      required: {
        value: false,
      },
      validate: {
        max: (value) => (value && value.length <= 500) || 'Max 500 characters are allowed',
      },
    },
  },
  city: {
    name: "city",
    validate: {
      required: {
        value: false,
      },
    },
  },
  state: {
    name: "state",
    validate: {
      required: {
        value: false,
      },
    },
  },
  subscriptionFee: {
    name: "subscriptionFee",
    validate: {
      pattern: {
        value: Constant.REGEX.DECIMAL_GREATER_ZERO,
        message: Message.ErrorMessage.SubscriptionFeeInvalid,
      },
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getState = (state) => {
  if (state) {
    return STATES.find(s => s.name === state)
  }
  return null
}

const EditForm = ({ user, userName, history, open, onSave, onCancel, busy }) => {
  const [form, setForm] = useState(null);
  const [state, setState] = useState(getState(user?.state));
  const [links, setLinks] = useState(user.external_links || []);
  const [error,setError]=useState("");

  const onFormSubmit = async () => {
    
    if (Object.keys(form.errors).length) return;

    const fillinks=links.filter(i=> i.name!='');
    console.log(fillinks);

    const data = {...form.getValues(),'external_links':fillinks};
    if(state) {
      data.state = state?.name
    }
    onSave(data);
  };

  const onCancelClick = async () => {
    onCancel && onCancel()
  }

  const previewProfile=async ()=>{
    console.log("redirect")
    history.push(`/p/${userName}`);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-slide-title" className="previreButt">
        <span>EDIT PROFILE</span>
        <Button
        fullWidth={false}
        color="primary"
        variant="contained"
        onClick={previewProfile}>
          Preview Profile
        </Button>  
      </DialogTitle>
      
      <DialogContent className="formDialog">
        <HookForm
          defaultForm={user}
          init={(form) => setForm(form)}
          onSubmit={onFormSubmit}
        >
          {(formMethod) => {
            return (
              <Box display="flex" flexDirection="column" height={1}>
                <Box marginBottom="1rem">
                  <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.name.validate}
                    name={EDIT_PROFILE_FORM.name.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder="Name"
                  />
                </Box>
                <Box marginBottom="1rem">
                  <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.userName.validate}
                    name={EDIT_PROFILE_FORM.userName.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="User Name"
                  />
                </Box>
                <Box marginBottom="1rem">
                  <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.bio.validate}
                    name={EDIT_PROFILE_FORM.bio.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="Enter your bio here"
                    rows={4}
                    multiline
                  />
                </Box>
                <Box marginBottom="1rem">
                  <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.city.validate}
                    name={EDIT_PROFILE_FORM.city.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="City"
                  />
                </Box>
                <Box marginBottom="1rem">
                  {/* <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.state.validate}
                    name={EDIT_PROFILE_FORM.state.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="State"
                  /> */}
                  <Autocomplete
                    value={state}
                    onChange={(event, newValue) => {
                      setState(newValue);
                    }}
                    options={STATES}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <MaterialTextField {...params} label="State" variant="outlined" />}
                  />
                </Box>
                <Box marginBottom="1rem">
                  <TextField
                    formMethod={formMethod}
                    rules={EDIT_PROFILE_FORM.subscriptionFee.validate}
                    name={EDIT_PROFILE_FORM.subscriptionFee.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="Subscription Fee"
                  />
                </Box>
                <DialogTitle id="alert-dialog-slide-title" className="innerTitle">LINKS
                <span>Share website and social media link on your page!</span></DialogTitle>
                {/* <Box className="greyBox">
                  <Box className="greyBoxTop">
                      <Switch
                        checked={state.checkedB} 
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                  </Box>
                  <TextField
                    formMethod={formMethod} 
                    name={EDIT_PROFILE_FORM.externalLinkTitle.name} 
                    type={"text"}
                    placeholder="Title" 
                  />
                  <TextField
                    formMethod={formMethod} 
                    name={EDIT_PROFILE_FORM.externalLink.name} 
                    type={"text"}
                    placeholder="url" 
                  />
                </Box>
                <Box mt={2} textAlign="center">
                  <Button variant="outlined" color="primary" >
                    + ADD MORE
                  </Button>
                </Box>   */}
                {user.external_links!=null && user.external_links.length>0?
                <GroupLinks onChange={setLinks} onError={setError} defaulValue={user.external_links}/> :
                <GroupLinks onChange={setLinks} onError={setError}/>
                }
              </Box>
            );
          }}
        </HookForm>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={onFormSubmit}
          color="primary"
          fullWidth={false}
          loading={!!busy}
          disabled={error!=''}
        >
          SAVE
        </CustomButton>
        <CustomButton onClick={onCancelClick} color="primary" fullWidth={false}>
          CANCEL
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditForm)
);
