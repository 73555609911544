import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { showToast } from "../../state/ducks/utils/operations";
import { FiEdit3 } from "react-icons/fi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: "2px solid #40D44A",
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    border: "2px solid #40D44A",
  },
}));

const ProfileAvatar = ({ name, pic, onChange, showToast, editable = true }) => {
  const classes = useStyles();

  const fileUploadRef = useRef();

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length) {
      const file = files[0];
      if (Constant.PROFILE_FILE_TYPES.includes(file.type)) {
        onChange && onChange(file);
      } else {
        showToast({
          message: Message.ErrorMessage.ProfilePicType,
          type: "error",
        });
      }
    }
    clearData();
  };

  const openFilePicker = () =>
    fileUploadRef.current && fileUploadRef.current.click();

  const clearData = () =>
    fileUploadRef.current && (fileUploadRef.current.value = "");

  return (
    <Box className={classes.root}>
      {editable ? (
        <Box className="editIcon"
          zIndex={1}
          position={"absolute"}
          bgcolor="#40D44A"
          borderRadius={"50%"}
          height={32}
          width={32}
          display="flex"
          justifyContent="center"
          alignItems="center"
          right={"8px"}
          top={"8px"}
          onClick={openFilePicker}
        >
              <Box className="tikIcon">
                <FiEdit3/>
              </Box>
        </Box>
      ) : null}
      <Avatar
        alt={name}
        src={
          pic
            ? `${Constant.PROFILE_BUCKET_PATH}/${pic}?timestamp=${Date.now()}`
            : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_USER_IMAGE}`
        }
        className={classes.large}
      />
      <input
        type="file"
        onChange={handleFileChange}
        ref={fileUploadRef}
        className="hidden-file-picker"
        accept="image/gif,image/jpeg,image/png"
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAvatar);
