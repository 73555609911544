import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const BULLET_POINTS = [
  "Exlusive, ad-free, access to an ever growing list of my premium recipes.",
  "Take the guesswork out of cooking with recipes you know and trust",
  "Sort and organize  recipes in one, easy to use place.",
  "Support your favorite chefs and join their inner circle!",
];

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bulletList: {
    paddingLeft: 16
  },
  bulletListItem: {
    textAlign: 'left'
  },
});

export default function SubscribeBulletList() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box>
        <ul className={classes.bulletList}>
          {BULLET_POINTS.map((point, index) => (
            <li key={index}>
              <Typography
                className={classes.bulletListItem}
                color="textSecondary"
              >
                {point}
              </Typography>
            </li>
          ))}
        </ul>
     </Box>
      </CardContent>
    </Card>
  );
}
