import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logout } from "../../state/ducks/auth/operations";
import { getSubscriptions } from "../../state/ducks/user/operations";
import { getPopularChefs } from "../../state/ducks/subscribe/operations";

import "./Home.scss";
import { Typography, Box, Container, Button } from "@material-ui/core";
import UserCard from "../../components/UserCard/UserCard";

const Home = ({
  logout,
  getSubscriptions,
  profile,
  subscriptions = [],
  popularChefs = [],
  getPopularChefs,
  history,
}) => {

  const [flag,setFlag]=useState('Popular');
  const [btntxt,setBtnTxt]=useState('SHOW ALL');
  const [title,setTitle]=useState('Popular Chefs');
  const [offset,setOffset]=useState(10);

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if(bottom)
    {
      //setLoader(true);
           setOffset(()=>{
              return offset+10;
           })
    }
  };

  useEffect(() => {
    //window.addEventListener('scroll', onScroll,true);
    getPopularChefs(flag,offset);
    getSubscriptions(profile.id);

    // return () => {
    //   window.removeEventListener('scroll', onScroll,true);
    // }; 
  }, [flag,offset]);

  const handleToggle=(btntxt)=>{
    if(btntxt=='SHOW ALL')
    {
        setBtnTxt('COLLAPSE');
        setFlag('All');
        setTitle('All Chefs');
    }
    else
    {
      setBtnTxt('SHOW ALL');
      setFlag('Popular');
      setTitle('Popular Chefs');
    }
  }

  const redirectToUserProfile = (userName) => {
    history.push(`/u/${userName}`);
  };

  return (
    <Container maxWidth="md">
      <Box className="chefList">
      {subscriptions.length>0 &&
        <Box className="HeadOut">
          <Typography variant="h6" className="sectHeading">
            Your Chefs
          </Typography>
        </Box>
      }
        {subscriptions &&
          subscriptions.map &&
          subscriptions.map((user, index) => (
            <UserCard
              key={index}
              user={user}
              onClick={redirectToUserProfile}
            ></UserCard>
          ))}
      </Box>
      <Box className="chefList">
        <Box className="HeadOut">
          <Typography variant="h6" className="sectHeading">
            {title}
          </Typography>
          <Button className="collaps" onClick={()=>handleToggle(btntxt)}>
            {btntxt}
          </Button>
        </Box>
        {popularChefs.length>0 &&
          popularChefs.map((user, index) =>
            user.userName !== profile?.userName ? (
              <UserCard
                key={index}
                user={user}
                onClick={redirectToUserProfile}
              ></UserCard>
            ) : null
          )}
        {popularChefs.length>0 && btntxt=="COLLAPSE" ?
     popularChefs.length!==popularChefs[0].totcount && 
     <Box mt={2} pb={2}><Button className="sendbtn" color="primary" variant="contained" onClick={onScroll}>Load more</Button></Box>
     :null}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth.session.user,
    subscriptions: state.user.subscriptions,
    popularChefs: get(state, "subscribe.popularChefs", []),
  };
};

const mapDispatchToProps = { logout, getSubscriptions, getPopularChefs };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
