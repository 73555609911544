import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export default function withAdminAuthentication(WrappedComponent) {
  const WithAdminAuthentication = (props) => {
    if (!props.isAuthenticated) {
      return <Redirect to="/welcome" />;
    }
    return <WrappedComponent {...props} />;
  };

  const { bool } = PropTypes;

  WithAdminAuthentication.propTypes = {
    isAuthenticated: bool.isRequired,
  };

  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    session: state.auth.session,
  });

  return connect(mapStateToProps)(WithAdminAuthentication);
}
