import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { AiOutlineUser } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineHeart } from "react-icons/ai";
import { VscHome } from "react-icons/vsc";
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
} from "@material-ui/icons";
import Constant from "../../util/constant";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

const BottomMenu = (props) => {
  const classes = useStyles();
  const [, setValue] = React.useState(0);

  const navigateToHome = () => {
    props.history.push("/");
  };

  const navigateToProfile = () => {
    props.history.push(`/u/${props?.profile?.userName}`);
  };

  const saveRecipe = () => {
    //props.history.push(`/u/${props?.saveRecipe?.userName}`);
    props.history.push(`/saveRecipe`);
  };

  const navigateToAccount = () => {
    props.history.push(`/account`);
  };

  return (
    <BottomNavigation
      value={
          Constant.MENU.PROFILE === props.selectedMenu
          ? 1
          : Constant.MENU.SAVEDRECIPE === props.selectedMenu
          ? 2
          : Constant.MENU.ACCOUNT === props.selectedMenu
          ? 3
          : 0
      }
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="HOME"
        icon={<VscHome />}
        onClick={navigateToHome}
      />
      <BottomNavigationAction
        label="PROFILE"
        icon={<AiOutlineUser />}
        onClick={navigateToProfile}
      />

      <BottomNavigationAction
        label="SAVEDRECIPE"
        icon={<AiOutlineHeart />}
        onClick={saveRecipe}
      />
      <BottomNavigationAction
        label="ACCOUNT"
        icon={<IoSettingsOutline />}
        onClick={navigateToAccount}
      />
     
    </BottomNavigation>
  );
};

const mapStateToProps = (state) => {
  return {
    sideMenu: state.utils.sideMenu,
    profile: state.auth.session.user,
    selectedMenu: state.utils.selectedMenu,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomMenu)
);
