import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const userReducer = createReducer({})({
    [types.GET_USER_BY_USERNAME_COMPLETED]: (state, action) => {
        const user = get(action, 'payload.data', {});
        return user;
    },
    [types.GET_USER_BY_ID_COMPLETED]: (state, action) => {
        const user = get(action, 'payload.data', {});
        return user;
    },
});

const subscriptionReducer = createReducer([])({
    [types.GET_SUBSCRIPTIONS_COMPLETED]: (state, action) => {
        const subscriptions = get(action, 'payload.data', {});
        return subscriptions;
    },
});

export default combineReducers({
    user: userReducer,
    subscriptions: subscriptionReducer
});
