import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Select, Modal, MenuItem, NativeSelect, Avatar, Button } from "@material-ui/core";
import { AiFillHeart } from "react-icons/ai";
import { RiChat3Line } from "react-icons/ri";
import Constant from "../../util/constant";

import {
  updateCoverImage,
  updateProfileImage,
  updateProfile,
} from "../../state/ducks/auth/operations";

import { showToast } from "../../state/ducks/utils/operations";
import { getUserRecipesBin,getTopRecipeTagsBin } from "../../state/ducks/recipe/operations";
import Loader from '../../loader';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu'; 
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import CloseIcon from '@material-ui/icons/Close';
import "./SavedRecipe.scss";


const SavedRecipe = (props) => { 

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const { profile, getUserRecipesBin, recipes, tags,getTopRecipeTagsBin } = props;
  const [sortby,setSortBy]=useState("");
  const [filterby,setFilterBy]=useState("");
  const [offset,setOffset]=useState(10);
  const [loader, setLoader] = React.useState(false);

  const ITEM_HEIGHT = 40;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = (e) => {
  //   setFilterBy(e);
  //   setAnchorEl(null);
  // };

  const handleChange = (event) => {
   // setLoader(true);
    setSortBy(event.target.value); 
    // setLimit(1);
  };

  const handleChangeFilter = (value) => {
    //setLoader(true);
    if(filterby==value)
    {
      setFilterBy(''); 
    }
    else
    {
      setFilterBy(value); 
    }
    
    setOpenModal(false);
  };

  const onScroll = (e) => {
    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // if(bottom)
    // {
      setLoader(true);
           setOffset(()=>{
              return offset+10;
           })
    // }
  };

  

  useEffect(() => {
  //  window.addEventListener('scroll', onScroll,true);
    getTopRecipeTagsBin(profile.id);
    if(sortby=="")
    {
        if(filterby=="")
        getUserRecipesBin(profile.id,'createdAt','All',offset);
        else
        getUserRecipesBin(profile.id,'createdAt',filterby,offset);


        loading(offset)

       // setLoader(false);
    }
    else
    {
        if(filterby=="")
        getUserRecipesBin(profile.id,sortby,'All',offset);
        else
        getUserRecipesBin(profile.id,sortby,filterby,offset);

        loading(offset)

     //   setLoader(false);
    }

    // return () => {
    //   window.removeEventListener('scroll', onScroll,true);
    // }; 
  }, [sortby,filterby,offset]);

  const redirectToRecipe = (id) => {
    props.history.push(`/recipe/${id}`);
  };
  const handleCloseOut = () => {
    setAnchorEl(null);
  };

  const loading=(offset)=>{
   
    setTimeout(()=>{
      setLoader(false);
    },2800)
    
  }
 
  return (
    <>
      <Box height={1} id="header">
        <Container maxWidth="md">
        <Box className="sortOut"  mt={2} mb={2}>
            <Typography variant="h6">
              My Saved Recipes
            </Typography>
            <Box>
            {loader ? <Loader/> : null}
            <div className="fiterOut">
            <NativeSelect className="mySelect"
              displayEmpty
              value={sortby} 
                onChange={handleChange}>
                <option value="" disabled>Sort</option>
                <option value="createdAt">Recent</option>
                <option value="likelength">Popular</option>
                <option value="cookingTime">Cook Time</option>
                <option value="difficulty">Difficulty</option>
                <option value="name">A to Z</option>
            </NativeSelect>
            <Box className="mySelectRight" onClick={handleOpenModal}>
              <SortRoundedIcon/>
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
            <div className="filterModal">
              <h5>Filters</h5>
              <Box className="filterTags">
              { tags.length>0 ?
                  tags.map((option) => (
                    <span className={filterby==option ? 'selectedTag' : ''} key={option} onClick={(e)=>handleChangeFilter(option)}>
                      {option}
                    </span>
                  )) : ''}
              </Box>
              <Box className="modalClose" onClick={handleCloseModal}>
                  <CloseIcon/>
              </Box>
            </div>
            </Modal>
            </div>
          </Box>
          </Box>
          { recipes.length>0 ?
          recipes.map((recipe, index) => (
          <Box mt={2} onClick={() => redirectToRecipe(recipe.repid)} key={index}>
            <Box className="recipe-card">
              <img src={
                  recipe.recipecover
                    ? `${Constant.RECIPE_BUCKET_PATH}/${
                        recipe.recipecover
                      }?timestamp=${Date.now()}`
                    : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
                } className="recipe-card-img" />
              <Box className="recipe-nameplate"> 
                <Box className="cardInnerBx">
                  <Box className="recCardInTop">
                      <Avatar className="userIcon" 
                      src={
                        recipe.profilePic
                          ? `${Constant.PROFILE_BUCKET_PATH}/${
                              recipe.profilePic
                            }?timestamp=${Date.now()}`
                          : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_USER_IMAGE}`
                      }
                      
                      />
                      <Typography>
                        {recipe.name}
                    </Typography>
                  </Box>
                  <Box className="recCardIn">
                      <Box display="flex" alignItems="center" className="recipe-name">
                        <Box className="pinOut">
                          <Typography variant="h6">{recipe.recipename}</Typography>
                        </Box>
                        <Typography className="recipe-time">{`${recipe.cookingTime} min`}</Typography>
                      </Box>
                      <Box className="recipe-likes recipeLikesProfile">
                        <Box className="userLike"><AiFillHeart />  
                        <Typography>{recipe.count}</Typography>
                        </Box>
                        <Box className="userCmt">
                          <RiChat3Line /> <Typography>{recipe.comcount}</Typography>
                        </Box>  
                      </Box>
                  </Box>
                </Box>
              </Box>             
            </Box>
          </Box>
          )) : (
            <Box mt={4}>
              <Typography>No recipe found!!</Typography>
            </Box>
          )}
             {recipes.length>0 ?
          recipes.length!==recipes[0].totrecipe && 
          <Box mt={2} pb={2}><Button color="primary" variant="contained" onClick={onScroll}>Load more</Button></Box>
          :null}
        </Container>
      </Box>
     
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
    recipes: state.recipe.recipesbin,
    tags: state.recipe.tags
  };
};

const mapDispatchToProps = {
  updateCoverImage,
  updateProfileImage,
  showToast,
  updateProfile,
  getUserRecipesBin,
  getTopRecipeTagsBin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedRecipe));

