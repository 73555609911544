import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Switch ,Select, MenuItem} from "@material-ui/core";

import Constant from "../../util/constant";
import Message from "../../util/message";
import {
  updateCoverImage,
  updateProfileImage,
  updateProfile,
} from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";
import { editRecipe, getRecipe } from "../../state/ducks/recipe/operations";

import CoverUploader from "../../components/CoverUploader/CoverUploader";

import "./EditRecipe.scss";
import GroupInput from "../../components/GroupInput/GroupInput";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";

const RECIPE_FROM = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
    },
  },
  description: {
    name: "description",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.DescritionEmpty,
      },
      validate: {
        max: (value) => (value && value.length <= 500) || 'Max 500 characters are allowed',
      },
    },
  },
  cookingTime: {
    name: "cookingTime",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.CookingTimeEmpty,
      },
      pattern: {
        value: Constant.REGEX.NUMBER,
        message: Message.ErrorMessage.CookingTimeNumber,
      },
      validate: {
        max: (value) => (value && value < 999) || 'Cooking time should be less than 999 minutes',
      },
    },
  },
  activeTime: {
    name: "activeTime",
  },
  difficulty: {
    name: "difficulty",
  },
  servings: {
    name: "servings",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.ServingsEmpty,
      },
      pattern: {
        value: Constant.REGEX.NUMBER,
        message: Message.ErrorMessage.ServingsNumber,
      },
      validate: {
        max: (value) => (value && value < 999) || 'Servings should be less than 999',
      },
    },
  },
};

const EditRecipe = (props) => {
  const {
    profile,
    busy,
    editRecipe,
    showToast,
    history,
    getRecipe,
    recipe,
  } = props;
  const [form, setForm] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [amount, setAmounts] = useState([]);
   const [tags, setTags] = useState([]);
  const [coverPic, setCoverPic] = useState();
  const [coverFile, setCoverFile] = useState();
  const [defaultIngredients, setDefaultIngredients] = useState([]);
  const [defaultAmounts, setDefaultAmounts] = useState([]);
  const [defaultInstructions, setDefaultInstructions] = useState([]);
  // const [defaultTags, setDefaultTags] = useState([]);
  const [isPinned, setIsPinned] = useState(false);
  const [difficulty, setDifficulty] = useState('');
  const {
    match: { params },
  } = props;

  var arr=[],keys=[];
  useEffect(() => {
    getRecipe(params.id,profile.id);
  }, []);

  useEffect(() => {
    setIsPinned(!!recipe.isPinned);
  }, [recipe]);

  useEffect(() => {
    if (form) {
      form.setValue("name", recipe.name, { shouldValidate: true });
      setDifficulty(recipe.difficulty);
      form.setValue("description", recipe.description, {
        shouldValidate: true,
      });
      form.setValue("cookingTime", recipe.cookingTime, {
        shouldValidate: true,
      });
      form.setValue("activeTime", recipe.activeTime, {
        // shouldValidate: true,
      });
      form.setValue("servings", recipe.servings, { shouldValidate: true });
      setDefaultInstructions(get(recipe, "instructions", []));
      // setDefaultTags(get(recipe, "tags", []));
      setDefaultIngredients(
        get(recipe, "ingredients", []).map((ingredient) => ingredient)
      );
      
      setDefaultAmounts(
        get(recipe, "ingredients", []).map((ingredient) => ingredient.amounts)
      );

      setInstructions(get(recipe, "instructions", []));
      setIngredients(
        get(recipe, "ingredients", []).map((ingredient) => ingredient.name)
      );

      setAmounts(
        get(recipe, "ingredients", []).map((ingredient) => ingredient.amounts)
      );
     
      // setTags(
      //   get(recipe, "tags", []).map((tags) => tags.name)
      // );

      setTags(recipe.tags);

      if(recipe.tags!==null){
        localStorage.setItem('tagsedit',JSON.stringify(recipe.tags));
      }
      else{
        localStorage.setItem('tagsedit',JSON.stringify([]));
      }
      
    }
  }, [recipe]);

  const onFormSubmit = async () => {
    if (Object.keys(form.errors).length) return;

    try {
      const values = form.getValues();
      // const formData = new FormData();
      // formData.append("userId", profile.id);
      // formData.append("coverPic", coverFile);
      // formData.append("ingredients", JSON.stringify(ingredients));
      // formData.append("instructions", JSON.stringify(instructions));
      // formData.append("isPinned", JSON.stringify(isPinned));
      // for (const key of Object.keys(values)) {
      //   formData.append(key, values[key]);
      // }
      // await editRecipe({ id: params.id, form: formData });
      // showToast({
      //   message: Message.SuccessMessage.RecipeUpdated,
      //   type: "success",
      // });
      // history.goBack();
      for (const key of Object.keys(values)) {
        // formData.append(key, values[key]);
        keys[key]=values[key];
      }

      // console.log("ing >> "+ingredients);
      // console.log("amount >> "+amount);

      let ing=ingredients.filter(i=> i!==null && i!=='')
      let ingarr=[];
      for(let i=0;i<ing.length;i++)
      {
          let obj={
            'name':ing[i],
            'amounts':amount[i]
          }
          ingarr.push(obj);
      }

      //console.log(ingarr);

      arr['paramId']=params.id;
      arr['userId']=profile.id;
      arr['coverPic']=coverFile;
      arr['ingredients']=JSON.stringify(ingredients);
      arr['instructions']=JSON.stringify(instructions);
      arr['amounts']=JSON.stringify(ingarr);
      arr['isPinned']=JSON.stringify(isPinned);
      arr['difficulty']=difficulty;
      arr['key']=keys;
      arr['tags']=tags;

     
        props.history.push({
          pathname: "/edittags",
          state: { arr1: arr},
        });
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const handleChange = (event) => {
   
    setDifficulty(event.target.value);
  };

  const onCoverChange = (file) => {
    setCoverFile(file);
    var fr = new FileReader();
    fr.onload = () => setCoverPic(fr.result);
    fr.readAsDataURL(file);
  };

  return (
    <>
      <HookForm
        defaultForm={{}}
        init={(form) => setForm(form)}
        onSubmit={onFormSubmit}
      >
        {(formMethod) => {
          return (
            <Box height={1}>
              <Box
                className="cover-banner recipeImage"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                position="relative"
              >
                <Container maxWidth="md" className="myRecipe"> 
                <Box zIndex={1}>
                  <CoverUploader onChange={onCoverChange} />
                </Box>
                </Container>
                <Box position="absolute" height={1} width={1}>
                  <img
                    className="cover-img"
                    alt={"recipe"}
                    src={
                      coverPic ||
                        (recipe.coverPic ?
                        `${Constant.RECIPE_BUCKET_PATH}/${recipe.coverPic}?timestamp=${Date.now()}` :
                        `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`)
                    }
                  />
                </Box>
              </Box>
              <Container maxWidth="md" className="whtInput"> 
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Typography align="left" variant="h6">
                    Pin this recipe
                  </Typography>
                  <Switch
                    checked={isPinned}
                    onChange={() => setIsPinned(!isPinned)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box display="flex" mt={1}>
                  <Box flexGrow={1}>
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.name.validate}
                      name={RECIPE_FROM.name.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Recipe Title"
                    />
                  </Box>
                </Box>
                <Box display="flex" mt={1}>
                  <Box flexGrow={1}>
                      <TextField
                        formMethod={formMethod}
                        rules={RECIPE_FROM.servings.validate}
                        name={RECIPE_FROM.servings.name}
                        errors={formMethod?.errors}
                        type="text"
                        placeholder="Servings"
                      />
                  </Box>
                  <Box flexGrow={1}>
                    <Select className="mySelectNew"
                      formMethod={formMethod}
                      name={RECIPE_FROM.difficulty.name}
                      errors={formMethod?.errors}
                      labelId=" "
                      id=" " 
                      displayEmpty
                      value={difficulty} 
                      onChange={handleChange}>
                        <MenuItem value="" disabled>Choose Difficulty</MenuItem>
                        <MenuItem value="Easy">Easy</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Challenging">Challenging</MenuItem>
                    </Select>
                    </Box>
                </Box>
                <Box display="flex" mt={1}>
                  <Box flexGrow={1}>
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.cookingTime.validate}
                      name={RECIPE_FROM.cookingTime.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Total Time (Minutes)"
                    />
                  </Box>
                  <Box flexGrow={1} ml={2}>
                    <TextField
                      formMethod={formMethod}
                    
                    //  rules={RECIPE_FROM.cookingTime.validate}
                      name={RECIPE_FROM.activeTime.name}
                    //  errors={formMethod?.errors}
                      type="text"
                      placeholder="Active Time (Optional)"
                    />
                  </Box>
                  
                </Box>
                <Box mt={2}>
                  <Typography align="left" variant="h6">
                    Description
                  </Typography>
                  <Box mt={2}>
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.description.validate}
                      name={RECIPE_FROM.description.name}
                      errors={formMethod?.errors}
                      type="text"
                      multiline
                      rows={5}
                    />
                  </Box>
                </Box>
                <GroupInput title="Ingredients" onAmount={setAmounts} onChange={setIngredients} defaulValue={defaultIngredients} defaulValueAmt={defaultAmounts} flag="edit"/>
                <GroupInput
                  title="Instructions"
                  multiline
                  rows={3}
                  onChange={setInstructions}
                  defaulValue={defaultInstructions}
                />
                <Box mt={2} pb={2} textAlign="center">
                  <CustomButton
                    className="myButton"
                    type="submit"
                    color="primary"
                    fullWidth={false}
                    loading={!!busy}
                    disabled={
                      !formMethod?.formState.isValid ||
                      !instructions.length ||
                      !ingredients.length ||
                      !difficulty
                    }
                  >
                    SAVE YOUR RECIPE
                  </CustomButton>
                </Box>
              </Container>
            </Box>
          );
        }}
      </HookForm>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
    recipe: state.recipe.recipe,
  };
};

const mapDispatchToProps = {
  updateCoverImage,
  updateProfileImage,
  showToast,
  updateProfile,
  editRecipe,
  getRecipe,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditRecipe)
);
