import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { storeTags } from "../../state/ducks/recipe/operations";
var arr = [];
const SpanTags = (props) => {
   
    const [isActive, setActive] = useState(props.isActive);
    const [tags, setTags] = useState();
    if (localStorage.getItem("addrecipe") !== null) {
        arr=[];
        
        localStorage.removeItem("addrecipe");
      }

    const handleClick = async (id, event) => {
        setActive(!isActive);

        if (arr.includes(id)) {
            var index = arr.indexOf(id)
            console.log(index);
            if (index !== -1) {
                arr.splice(index, 1);
            }
        } else {
            arr.push(id);
        }
        setTags(arr);
        localStorage.setItem('tags',JSON.stringify(arr));
    
      
    }

    return (
        
        <span className={isActive ? "selectedTag" : "deselectedTag"} id={props.id} onClick={(e) => handleClick(props.id, e)}>{props.content}</span>
    );
};

const mapStateToProps = (state) => {
    return {
        
    };
};

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(SpanTags);
