import React from "react";
import { get as _get } from "lodash";
import { Controller } from "react-hook-form";
import { default as TextBox } from "@material-ui/core/TextField";
import "./TextField.scss";

const TextField = (props) => {
  let {
    errors,
    placeholder,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    onKeyDown,
    formMethod,
    rows,
    fullWidth = true,
    rules,
    multiline,
    className,
  } = props;

  let hasError = errors[name] !== undefined;
  return (
    <Controller
      render={({ onChange, value = "" }) => {
        return (
          <TextBox
            className={hasError ? `${className} error` : className}
            label={placeholder}
            variant="outlined"
            autoFocus={autoFocus}
            disabled={disabled}
            autoComplete="off"
            type={type}
            as={textarea}
            rows={rows}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            onChange={(e) => {
              onChange && onChange(e.target.value);
              props.onChange && props.onChange(e);
            }}
            value={value || ""}
            fullWidth={fullWidth}
            error={hasError}
            helperText={_get(errors, `${name}.message`, "")}
            multiline={multiline}
          />
        );
      }}
      name={name}
      control={formMethod?.control}
      rules={rules}
      defaultValue={""}
    />
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  noOfWeeks: false,
};

export default TextField;
