import React from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CustomButton from "../../components/CustomButton/CustomButton";

import { toggleSideMenu } from "./../../state/ducks/utils/operations";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -24,
  },
  title: {
    flexGrow: 1,
    marginLeft: -24,
  },
  loginButton: {
    position: "absolute",
    right: 16,
  },
}));

export const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const homeNavTo = props.isAuthenticated ? "/" : "/welcome";

  const onLoginClick = () => {
    props.history.push({
      pathname: "/login",
      state: { userName: props?.location?.pathname.split("/").pop() },
    });
  };

  console.log(props.location);

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className="appHeader">
        <Toolbar>
          {location.pathname === "/" || !props.isAuthenticated ? (
            <Box width={24}></Box>
          ) : (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={props.history.goBack}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography
            variant="h6"
            className={classes.title}
            onClick={() => props.history.push(homeNavTo)}
          >
            <Box className="appName">
              <span>
                <img src={mainLogo} alt="logo" />
              </span>
              <Typography variant="h5" color="inherit">
                Panfavorite
              </Typography>
            </Box>
          </Typography>
          {!props.isAuthenticated &&
          props?.location?.pathname.includes("/u/") ? (
            <CustomButton
              className={classes.loginButton}
              type="button"
              color="primary"
              fullWidth={false}
              onClick={onLoginClick}
            >
              Login
            </CustomButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    selectedMenu: state.utils.selectedMenu,
  };
};

const mapDispatchToProps = { toggleSideMenu };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
