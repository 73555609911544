import React, { useEffect, useState,useRef  } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Link, MenuItem, Button, Modal, NativeSelect } from "@material-ui/core";
import { isEmpty } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Constant from "../../util/constant";
import coverPic from "../../assets/images/cover.jpg";
import { getUserById, getUserByUserName,} from "../../state/ducks/user/operations";
import { showToast } from "../../state/ducks/utils/operations";
import { getUserRecipes,likeRecipe,getTopRecipeTags,getUserLockedRecipes } from "../../state/ducks/recipe/operations";
import { subscribe, unsubscribe } from "../../state/ducks/subscribe/operations";
import LockIcon from '@material-ui/icons/Lock';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu'; 
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SortRoundedIcon from '@material-ui/icons/SortRounded';

import Message from "../../util/message";

import Avatar from "../../components/Avatar/Avatar";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import SubscribeDetailCard from "../../components/SubscribeDetailCard/SubscribeDetailCard";
import SubscribeBulletList from "../../components/SubscribeBulletList/SubscribeBulletList";
import CustomButton from "../../components/CustomButton/CustomButton";
import CloseIcon from '@material-ui/icons/Close';
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import Loader from '../../loader';
import { RiChat3Line } from "react-icons/ri";

import "./Profile.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = (props) => {

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const {
    user,
    getUserById,
    busy,
    getUserRecipes,
    getUserLockedRecipes,
    recipes,
    recipelocked,
    profile,
    showToast,
    subscribe,
    history,
    unsubscribe,
    getUserByUserName,
    getTopRecipeTags,
    likeRecipe,
    tags
  } = props;

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [sortby,setSortBy]=useState("");
  const [filterby,setFilterBy]=useState("");
  const [hasmore,setHasMore]=useState(true);
  const [offset,setOffset]=useState(10);
  const [items,setItems]=useState([]);
  const [loader, setLoader] = React.useState(false);

  const onLikeClick=async(flag,recipeid,userid)=>{
    console.log(flag)
    let likeflag;
    setLoader(true);
    if(flag=='nolike')
    {
        likeflag=true;
    }
    else
    {
        likeflag=false;
    }

    try {
      const formData = new FormData();
      formData.append("recipeid", recipeid);
      formData.append("userid",userid);
      formData.append("is_liked", likeflag);
      await likeRecipe(formData);
      
      if(flag=='nolike')
      {
        setLoader(false);
        showToast({
          message: Message.SuccessMessage.RecipeLiked,
          type: "success",
          });
      }
      else
      {
        setLoader(false);
        
        showToast({
          message: Message.SuccessMessage.RecipeUnLiked,
          type: "success",
          });
      }
      
    } catch (err) {
      setLoader(false);
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  }
 
  const ITEM_HEIGHT = 40;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setFilterBy(e);
    setAnchorEl(null);
  };

  const handleCloseOut = () => {
    setAnchorEl(null);
  };


  const isSubscribed = !!get(user, "subscribers", []).find(
    (subscriber) => subscriber.subscriberId === profile?.id
  );

  const redirectToRecipe = (id) => {
    props.history.push(`/recipe/${id}`);
  };

  const onCancel = () => setOpen(false);

  const openConfirmationDialog = () => setOpen(true);

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleChangeFilter = (value) => {
    if(filterby==value)
    {
      setFilterBy(''); 
    }
    else
    {
      setFilterBy(value); 
    }
    
    setOpenModal(false);
  };


  
  const onScroll = (e) => {
      setLoader(true);
           setOffset(()=>{
              return offset+10;
           })
    
  };

  const loading=(offset)=>{
   
    setTimeout(()=>{
      setLoader(false);
    },2800)
    
  }


  const openComment=async(props,recipe_id)=>{
    props.history.push(`/comments/${recipe_id}`);
  }


  useEffect(() => {
    console.log(isSubscribed);
  //  window.addEventListener('scroll', onScroll,true);
    const {
      match: { params },
    } = props;

    
    getUserByUserName(params?.userName).then(({ data }) => {
      // if(isSubscribed)
      // {
        setUserId(data?.id);
        getUserById(data?.id);
        getTopRecipeTags(data?.id);
        
        if(profile!=null){
          if(sortby=="")
          {
              if(filterby=="")
              getUserRecipes(data?.id,'createdAt','All',profile.id,offset);
              else
              getUserRecipes(data?.id,'createdAt',filterby,profile.id,offset);

              loading(offset)
          }
          else
          {
              if(filterby=="")
              getUserRecipes(data?.id,sortby,'All',profile.id,offset);
              else
              getUserRecipes(data?.id,sortby,filterby,profile.id,offset);


              loading(offset)

          }

        }
                // }
      // else
      // {
             getUserLockedRecipes(data?.id);
      // } 
      
    });

    // return () => {
    //   window.removeEventListener('scroll', onScroll,true);
    // }; 
  },[sortby,filterby,offset]);

  

  const renderRecipes = (props) => { 
    let tot=recipes.length;
  //  console.log("tot>> "+tot+" totrecords>> "+JSON.stringify(recipes));
    return (
    <> 
    {
    recipes.length ?
    (
      recipes.map((recipe, index) => ( 
        <Box className="proLikeOut">
        <Box mt={2} onClick={() => redirectToRecipe(recipe.id)} key={index}>  
          <RecipeCard
            key={recipe.id}
            recipeid={recipe.id}
            userid={profile.id}
            name={recipe.name}
            props={props}
            isPinned={recipe.isPinned}
            cookingTime={`${recipe.cookingTime} min`}
            likecnt={`${recipe.likelength}`}
            likebyme={`${recipe.likebyme}`}
            coverPic={
              recipe.coverPic
                ? `${Constant.RECIPE_BUCKET_PATH}/${
                    recipe.coverPic
                  }?timestamp=${Date.now()}`
                : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
            }
          />
          </Box>
          <Box className="recipe-likes recipeLikesProfile">
           {recipe.likebyme==false && 
                <>
                  <Box>
                <Button
                    className="ml-2 editButton"
                    onClick={()=>onLikeClick('nolike',recipe.id,profile.id)}
                  >
                  <AiOutlineHeart />
                  </Button> 
                  </Box>
                  {recipe.likelength>=10 ?<Typography>{recipe.likelength}</Typography> : null}              
                  </>
                 } 
                {recipe.likebyme==true &&
                <>
                  <Box>
                  <Button
                    className="ml-2 editButton"
                    onClick={()=>onLikeClick('like',recipe.id,profile.id)}
                  >
                  <AiFillHeart />
                  </Button>  
                  </Box> 
                  {recipe.likelength>=10 ?<Typography>{recipe.likelength}</Typography> : null}
                  </>
                }
            <Box className="userCmt">
            <Button onClick={()=>openComment(props,recipe.id)}>
              <RiChat3Line /> <span>{recipe.comcount}</span>
            </Button>  
            </Box>  
          </Box>
         
        </Box>
      ))
    )
     : (
      <Box mt={4}>
        <Typography>No recipe found!!</Typography>
      </Box>
    )
     }
     {recipes.length>0 ?
     tot!==recipes[0].totrecipe && 
     <Box mt={2} pb={2}><Button className="sendbtn" color="primary" variant="contained" onClick={onScroll}>Load more</Button></Box>
     :null}
    </>
    )
  };

  const onSubscribeClick = async () => {
    try {
      if (!props.isAuthenticated) {
        const {
          match: { params },
        } = props;
        return history.push({
          pathname: "/signup",
          state: {
            userName: params.userName,
          },
        });
      }
      //|| user.id==65
      if(user.id==61 || user.id==62 || user.id==63 || user.id==64 || user.id==65)
      {
        showToast({
          message: 
            Message.ErrorMessage.SubscriptionsNotAllowed
            ,
          type: "error",
        });

        history.push({
          pathname: "/"
        });
      }else
      {
        const response= await subscribe(userId);
      //const response = await subscribe(user.id);
      // console.log(JSON.stringify(response.data));
      history.push({
        pathname: "/checkout",
        state: {
          ...response.data,
          amount: user.subscriptionFee,
          free_trial:user.free_trial,
          name: user.name,
          chef_prod_price_id:user.chef_prod_price_id,
         
        },
      });
      }
      
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  

  const onUnsubscribeClick = async () => {
    try {
      await unsubscribe(userId);
      await getUserById(userId);
      setOpen(false);
      props.showToast({
        message: "Unsubscribed successfully.",
        type: "success",
      });
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const renderSubscriptionDetail = (links) => {
    return (
      <>
        <Box flex={1} mt={4} className="midCard">
          <SubscribeDetailCard
            subscriptionEnabled={true}
            subscriptionFee={user.subscriptionFee}
            onSubscribe={onSubscribeClick}
            loggedInUser={false}
          />
          <Box className="subscribeOut">
          <SubscribeBulletList />
          </Box>
        </Box>
        <Box mt={3} className="otherLinksbt" >
          {links!==null && links.length>0?
          links.map((link)=>(
            link.checked==true?
          <a href={link.url} target="_blank"> 
            {link.name}
          </a>
            : null 
          ))
          : null }
        </Box>
        {recipelocked.length>0 ?
         recipelocked.map((rlock) =>(
        <Box mt={2} >
            <Box className="recipe-card">
              <img src={
              rlock.coverPic
                ? `${Constant.RECIPE_BUCKET_PATH}/${
                  rlock.coverPic
                  }?timestamp=${Date.now()}`
                : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
            } className="recipe-card-img" />
              <Box className="recipe-nameplate"> 
                <Box className="lockRecipe">
                     <LockIcon/>
                </Box>
                <Box className="cardInnerBx">
                  <Box className="recCardIn">
                      <Box display="flex" alignItems="center" className="recipe-name">
                        <Box className="pinOut">
                          <Typography variant="h6">{rlock.name}</Typography>
                        </Box>
                        <Typography className="recipe-time">{rlock.cookingTime} Min</Typography>
                      </Box>
                  </Box>
                </Box>
              </Box>             
            </Box>
          </Box>
         )) : null}
      </>
    );
  };

  return (
    <>
      {isEmpty(user) ? (
        <Box
        id="header"
          display="flex"
          height={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">
            {busy ? "Loading..." : "Profile not found!!"}
          </Typography>
        </Box>
      ) : (
        <Box height={1}>
          <Box
            className="cover-banner"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            position="relative"
          >
            <Container maxWidth="md">
              <Box className="profileOut">
                <Box position="absolute" bottom={"-50%"} zIndex={1}>
                  <Avatar
                    editable={false}
                    pic={user.profilePic}
                    name={user.name}
                  />
                </Box>
              </Box>
            </Container>
            <Box position="absolute" height={1} width={1}>
              <img
                className="cover-img"
                alt={user.name}
                // src={
                //   user.coverPic
                //     ? `${Constant.COVER_BUCKET_PATH}/${
                //         user.coverPic
                //       }?timestamp=${Date.now()}`
                //     : `${Constant.DEFAULT_IMAGE_PATH}/${
                //         Constant.DEFAULT_USER_IMAGE
                //       }?timestamp=${Date.now()}`
                // }
                src={
                  user.coverPic
                    ? `${Constant.COVER_BUCKET_PATH}/${
                        user.coverPic
                      }?timestamp=${Date.now()}`
                    : `${Constant.DEFAULT_IMAGE_PATH}/${
                        Constant.DEFAULT_RECIPE_IMAGE
                      }?timestamp=${Date.now()}`
                }
              />
            </Box>
          </Box>
          <Container maxWidth="md" className="pageContainer">
         
          {isSubscribed ? (
                <Box className="sbcrBtn">
                  <CustomButton
                    className="leftButt smButtom"
                    onClick={openConfirmationDialog}
                    color="primary"
                    fullWidth={false}
                    loading={!!busy}
                  >
                    SUBSCRIBED ${user.subscriptionFee}
                  </CustomButton>{" "}
                </Box> 
                ) :null }

              <Box className="subscribedBtOut">
              <Box flex={1} mt={12} className="firstCard">
                <ProfileInfo user={user} editable={false} isSubscribed={isSubscribed}/> 
              </Box>

              {isSubscribed ? (
                <>
                {/* <Box flex={1} mt={2}>
                  <CustomButton
                    className="leftButt"
                    onClick={openConfirmationDialog}
                    color="primary"
                    fullWidth={false}
                    loading={!!busy}
                  >
                    SUBSCRIBED ${user.subscriptionFee}
                  </CustomButton>{" "}
                </Box> 
               */}

              <Box className="sortOut"  mt={2} mb={2}>
                <Typography variant="h6">
                  My Recipes
                </Typography>
                {loader ? <Loader/> : null}
                <div className="fiterOut">
            <NativeSelect className="mySelect"
              displayEmpty
              value={sortby} 
                onChange={handleChange}>
                <option value="" disabled>Sort</option>
                <option value="createdAt">Recent</option>
                <option value="likelength">Popular</option>
                <option value="cookingTime">Cook Time</option>
                <option value="difficulty">Difficulty</option>
                <option value="name">A to Z</option>
            </NativeSelect>
            <Box className="mySelectRight" onClick={handleOpenModal}>
              <SortRoundedIcon/>
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
            <div className="filterModal">
              <h5>Filters</h5>
              <Box className="filterTags">
              { tags.length>0 ?
                  tags.map((option) => (
                    <span className={filterby==option ? 'selectedTag' : ''} key={option} onClick={(e)=>handleChangeFilter(option)}>
                      {option}
                    </span>
                  )) : ''}

              </Box>
              <Box className="modalClose" onClick={handleCloseModal}>
                  <CloseIcon/>
              </Box>
            </div>
            </Modal>
            {/* <NativeSelect className="mySelect" displayEmpty onChange={handleChangeFilter} value={filterby} >
                <option  value="" disabled>Filter</option >
                { tags.length>0 ?
                  tags.map((option) => (
                    <option key={option} onClick={(e)=>handleClose(option)}>
                      {option}
                    </option>
                  )) : ''}
            </NativeSelect> */}
                </div>
              </Box>
              <Box>
            
          </Box>
          </> )
          : null}
        
              {isSubscribed ? 
              
              renderRecipes(props)
              
              : 
              renderSubscriptionDetail(user.external_links)
              
              }
              
            </Box>
           
          </Container>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-slide-title">UNSUBSCRIBE</DialogTitle>
            <DialogContent>
              <Typography>
                are you sure you want to unsubscribe from this chef?{" "}
              </Typography>
            </DialogContent>
            <DialogActions>
              <CustomButton
                onClick={onUnsubscribeClick}
                color="primary"
                fullWidth={false}
                loading={!!busy}
              >
                YES
              </CustomButton>
              <CustomButton
                onClick={onCancel}
                color="primary"
                fullWidth={false}
              >
                NO
              </CustomButton>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth.session.user,
    busy: state.busy,
    recipes: state.recipe.recipes,
    user: state.user.user,
    isAuthenticated: state.auth.isAuthenticated,
    tags: state.recipe.tags,
    recipelocked: state.recipe.recipelocked,
  };
};

const mapDispatchToProps = {
  showToast,
  getUserById,
  getUserRecipes,
  getUserLockedRecipes,
  getUserByUserName,
  subscribe,
  unsubscribe,
  likeRecipe,
  getTopRecipeTags
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
