import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../CustomButton/CustomButton";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  greenFont: {
    margin: "16px 0",
    color: "green",
  },
  subscriptionLabel: {
    background: "#f8f8f8",
    borderRadius: 16,
    marginTop: 16,
    padding: "12px 32px",
    display: "inline-block",
    textAlign: "center",
  },
  discountLabel: {
    marginTop: 16,
  },
});

const SubscribeBulletList = ({
  subscriptionFee,
  onSubscribe,
  busy,
  subscriptionEnabled,
  loggedInUser,
  disabled=false,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
      <Typography variant="h6" >MY ONLINE COOKBOOK</Typography>
        <CustomButton  color="primary" className="combButt" onClick={onSubscribe}>
        <Typography  
          // onClick={onSubscribe}
          loading={!!busy}
          disabled={!subscriptionEnabled} > 
          {`Subscribe ${!subscriptionEnabled && !loggedInUser ? '(Actived)' : ''}`}
        </Typography>
        <Box className="combButtInn">
        <Box ml={2}>
          {`$${subscriptionFee}`}
        </Box>
        <Box >PER MONTH</Box>
        </Box> 
        </CustomButton>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscribeBulletList)
);
