import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import JWTService from "./../../../services/jwt.service";
import StorageService from "./../../../services/localstorage.service";

const authReducer = createReducer(false)({
  [types.LOGOUT]: () => {
    StorageService.removeItem("isAuthenticated");
    StorageService.removeItem("user");
    StorageService.removeItem("token");
    return false;
  },
  [types.AUTHENTICATED]: () => true,
  [types.AUTHORIZATION_FAILED]: () => {
    StorageService.removeItem("isAuthenticated");
    StorageService.removeItem("user");
    StorageService.removeItem("token");
    return false;
  },
});

const initializeSessionReducer = createReducer(null)({
  [types.INITIALIZE]: (state, action) => {
    const user = action.payload.user;
    const tokens = action.payload.tokens;
    StorageService.setItem("isAuthenticated", true);
    StorageService.setItem("user", user);
    JWTService.saveToken(tokens);
    return { user, tokens };
  },
  [types.DESTROY]: () => {
    StorageService.removeItem("isAuthenticated");
    StorageService.removeItem("user");
    JWTService.destroyToken();
    return null;
  },
  [types.UPDATE_COVER_IMAGE_COMPLETED]: (state, action) => {
    const user = get(action, "payload.data", {});
    StorageService.setItem("user", user);
    return { ...state, user: get(action, "payload.data", {}) };
  },
  [types.UPDATE_PROFILE_IMAGE_COMPLETED]: (state, action) => {
    const user = get(action, "payload.data", {});
    StorageService.setItem("user", user);
    return { ...state, user: get(action, "payload.data", {}) };
  },
  [types.UPDATE_PROFILE_COMPLETED]: (state, action) => {
    const user = get(action, "payload.data", {});
    StorageService.setItem("user", user);
    return { ...state, user: get(action, "payload.data", {}) };
  },
  [types.GET_USER_BY_ID_COMPLETED]: (state, action) => {
    const userId = get(state, "user.id", 0);
    const user = get(action, "payload.data", {});
    if (user.id == userId) {
      StorageService.setItem("user", user);
      return { ...state, user };
    } else {
      return state;
    }
  },
});

export default combineReducers({
  isAuthenticated: authReducer,
  session: initializeSessionReducer,
});
