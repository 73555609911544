import * as types from "./types";

export const login = (payload) => ({
    type: types.LOGIN,
    meta: {
        async: true,
        blocking: true,
        path: "login",
        method: "POST",
        body: payload
    },
});

export const signup = (payload) => ({
    type: types.SIGNUP,
    meta: {
        async: true,
        blocking: true,
        path: "signup",
        method: "POST",
        body: payload
    },
});

export const confirmEmail = (payload) => ({
    type: types.SIGNUP,
    meta: {
        async: true,
        blocking: true,
        path: "verify-email",
        method: "POST",
        body: payload
    },
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const authenticated = () => ({
    type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = () => ({
    type: types.DESTROY
});

export const refreshToken = () => ({
    type: types.REFRESH_TOKEN
})

export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "forgot-password",
        method: "POST",
        body: payload
    },
});

export const resetPassword = (payload) => ({
    type: types.RESET_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "reset-password",
        method: "POST",
        body: payload
    },
});

export const verifyResetPasswordLink = (token) => ({
    type: types.VERIFY_RESET_PASSWORD_LINK,
    meta: {
        async: true,
        blocking: true,
        path: `auth/verify-forgot-password?token=${token}`,
        method: "GET"
    },
});

export const changePassword = (body) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: "auth/change-password",
        method: "PATCH",
        body
    },
});

export const getProfile = (id) => ({
    type: types.GET_PROFILE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "GET"
    },
});

export const updateCoverImage = ({ id, form }) => ({
    type: types.UPDATE_COVER_IMAGE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "PUT",
        body: form
    },
});

export const updateProfileImage = ({ id, form }) => ({
    type: types.UPDATE_COVER_IMAGE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "PUT",
        body: form
    },
});

export const updateProfile = ({ id, ...user }) => ({
    type: types.UPDATE_PROFILE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "PUT",
        body: user
    },
});

export const updateTrial = ({ id, ...trial }) => ({
    type: types.UPDATE_TRIAL,
    meta: {
        async: true,
        blocking: true,
        path: `user/trial/${id}`,
        method: "PUT",
        body: trial
    },
});

export const updateAccount = (body) => ({
    type: types.UPDATE_ACCOUNT,
    meta: {
        async: true,
        blocking: true,
        path: `profile`,
        method: "POST",
        body
    },
});


