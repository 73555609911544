import React from 'react';
import { Typography, Box } from "@material-ui/core";
import Constant from "../../util/constant";

const RecipeBox = ({ title, by, img }) => (
  <Box className="recipe-box">
    <Box className="chefOut">
      <img src={img ? `${Constant.PROFILE_BUCKET_PATH}/${img}`
          : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_USER_IMAGE}`} alt="chefs" />
    </Box>
    <Typography className="recipe-box-title">{title}</Typography>
    {/* <Typography className="recipe-box-by">- {by}</Typography> */}
  </Box>
);

export default RecipeBox;
