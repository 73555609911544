import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Switch, Select, MenuItem,InputLabel ,Input } from "@material-ui/core";

import Constant from "../../util/constant";
import Message from "../../util/message";
import {
  updateCoverImage,
  updateProfileImage,
  updateProfile,
} from "../../state/ducks/auth/operations";
import { showToast } from "../../state/ducks/utils/operations";
import { addRecipe } from "../../state/ducks/recipe/operations";

import CoverUploader from "../../components/CoverUploader/CoverUploader";

import "./AddRecipe.scss";
import GroupInput from "../../components/GroupInput/GroupInput";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";

const RECIPE_FROM = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
    },
  },
  description: {
    name: "description",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.DescritionEmpty,
      },
      validate: {
        max: (value) => (value && value.length <= 500) || 'Max 500 characters are allowed',
      },
    },
  },
  cookingTime: {
    name: "cookingTime",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.CookingTimeEmpty,
      },
      pattern: {
        value: Constant.REGEX.NUMBER,
        message: Message.ErrorMessage.CookingTimeNumber,
      },
      validate: {
        max: (value) => (value && value < 999) || 'Cooking time should be less than 999 minutes',
      },
    },
  },
  activeTime:{
    name : "activeTime"
  },
  difficulty: {
    name: "difficulty",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.Difficulty,
      },
    }
  },
  servings: {
    name: "servings",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.ServingsEmpty,
      },
      pattern: {
        value: Constant.REGEX.NUMBER,
        message: Message.ErrorMessage.ServingsNumber,
      },
      validate: {
        max: (value) => (value && value < 999) || 'Servings should be less than 999',
      },
    },
  },
};

var arr=[],keys=[];

const AddRecipe = (props) => {
  const { profile, busy, addRecipe, showToast, history } = props;
  const [form, setForm] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [amount, setAmounts] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [coverPic, setCoverPic] = useState();
  const [coverFile, setCoverFile] = useState();
  const [isPinned, setIsPinned] = useState(false);
  const [difficulty, setDifficulty] = useState('');
  const [diffmsg,setDiffmsg]=useState('');

  const onFormSubmit = async () => {
    if (Object.keys(form.errors).length) return;
    try {
      const values = form.getValues();
      for (const key of Object.keys(values)) {
        // formData.append(key, values[key]);
        keys[key]=values[key];
      }


      let ing=ingredients.filter(i=> i!==null && i!=='')
      let ingarr=[];
      for(let i=0;i<ing.length;i++)
      {
          let obj={
            'name':ing[i],
            'amounts':amount[i]
          }
          ingarr.push(obj);
      }

      // console.log(ingarr);

      arr['userId']=profile.id;
      arr['coverPic']=coverFile;
      arr['ingredients']=JSON.stringify(ingredients);
      arr['amounts']=JSON.stringify(ingarr);
      arr['instructions']=JSON.stringify(instructions);
      arr['isPinned']=JSON.stringify(isPinned);
      arr['difficulty']=difficulty;
      arr['key']=keys;

     // console.log(arr);
      localStorage.setItem('addrecipe','addrecipe');
      props.history.push({
        pathname: "/tags",
        state: { arr1: arr},
      });
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const handleChange = (event) => {
   
    setDifficulty(event.target.value);
    if(event.target.value!="")
    {
      setDiffmsg("");
    }
    
  };

  const handleBlur = (event) => {
  //  console.log("blur")
    if(difficulty=="")
    {
      setDiffmsg("Please Select Difficulty");
    }
    else{
      setDiffmsg("");
    }
  };


  const onCoverChange = (file) => {
    setCoverFile(file);
    var fr = new FileReader();
    fr.onload = () => setCoverPic(fr.result);
    fr.readAsDataURL(file);
  };

  

  return (
    <>
      <HookForm
        defaultForm={{}}
        init={(form) => setForm(form)}
        onSubmit={onFormSubmit}
      >
        {(formMethod) => {
          return (
            <Box height={1}>
              <Box
                className="cover-banner recipeImage"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                position="relative"
              >
                <Container maxWidth="md" className="myRecipe"> 
                <Box zIndex={1}>
                  <CoverUploader onChange={onCoverChange} />
                </Box>
                </Container>
                <Box position="absolute" height={1} width={1}>
                  <img className="cover-img" alt={"recipe"} src={coverPic || `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`} />
                </Box>
              </Box>
              <Container maxWidth="md" className="whtInput">
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Typography align="left" variant="h6">
                    Pin this recipe
                  </Typography>
                  <Switch
                    checked={isPinned}
                    onChange={() => setIsPinned(!isPinned)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box mt={1}>
                  <Box flexGrow={1}>
                   
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.name.validate}
                      name={RECIPE_FROM.name.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Recipe Title" />
                  </Box>
                </Box>
                <Box display="flex" mt={1} className="custFlex">
                  <Box className="custFlexInnLeft">
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.servings.validate}
                      name={RECIPE_FROM.servings.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Servings"
                    />
                  </Box>
                  <Box className="custFlexInnRight">
                  {/* <InputLabel htmlFor="age-helper">Choose Difficulty</InputLabel> */}
                  
                  <Select className="mySelectNew"
                    formMethod={formMethod}
                    name={RECIPE_FROM.difficulty.name}
                    rules={RECIPE_FROM.difficulty.validate}
                    errors={formMethod?.errors}
                    labelId=" "
                    // label="Choose Difficulty"
                    id=" " 
                    displayEmpty
                    value={difficulty} 
                    // input={<Input name="difficulty" id="age-helper" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                      <MenuItem value="">Choose Difficulty</MenuItem>
                      <MenuItem value="Easy">Easy</MenuItem>
                      <MenuItem value="Intermediate">Intermediate</MenuItem>
                      <MenuItem value="Challenging">Challenging</MenuItem>
                  </Select>
                  {/* {difficulty=="" ? <p>Please Select Difficulty</p>:null} */}
                  <Typography className="diffcultyError">{diffmsg}</Typography>
                  </Box>
                </Box>
                <Box display="flex" mt={1}>
                  <Box flexGrow={1}>
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.cookingTime.validate}
                      name={RECIPE_FROM.cookingTime.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Total Time (Minutes)"
                      onChange={handleBlur}
                    />
                  </Box>
                  <Box flexGrow={1} ml={2}>
                    <TextField
                        formMethod={formMethod}
                        name={RECIPE_FROM.activeTime.name}
                        //errors={formMethod?.errors}
                        type="text"
                        placeholder="Active Time (Optional)"
                      />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography align="left" variant="h6">
                    Description
                  </Typography>
                  <Box mt={1}>
                    <TextField
                      formMethod={formMethod}
                      rules={RECIPE_FROM.description.validate}
                      name={RECIPE_FROM.description.name}
                      errors={formMethod?.errors}
                      type="text"
                      multiline
                      rows={5}
                    />
                  </Box>
                </Box>

                <GroupInput title="Ingredients" onChange={setIngredients} onAmount={setAmounts} />
                <GroupInput title="Instructions" multiline rows={4} onChange={setInstructions} />

                <Box mt={3} pb={3} textAlign="center">
                  <CustomButton
                    className="myButton"
                    type='submit'
                    color="primary"
                    fullWidth={false}
                    loading={!!busy}
                    disabled={
                      !formMethod?.formState.isValid ||
                      !instructions.length ||
                      !ingredients.length ||
                      !difficulty

                    }
                  >
                    SAVE YOUR RECIPE
                  </CustomButton>
                 
                </Box>
              </Container>
            </Box>
          );
        }}
      </HookForm>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
  };
};

const mapDispatchToProps = {
  updateCoverImage,
  updateProfileImage,
  showToast,
  updateProfile,
  addRecipe,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddRecipe)
);
