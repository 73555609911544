export const USER_RECIPES = "USER_RECIPES";
export const USER_RECIPES_FAILED = "USER_RECIPES_FAILED";
export const USER_RECIPES_COMPLETED = "USER_RECIPES_COMPLETED";

export const USER_RECIPES_LOCKED = "USER_RECIPES_LOCKED";
export const USER_RECIPES_LOCKED_FAILED = "USER_RECIPES_LOCKED_FAILED";
export const USER_RECIPES_LOCKED_COMPLETED = "USER_RECIPES_LOCKED_COMPLETED";


export const USER_RECIPES_COMMENTS = "USER_RECIPES_COMMENTS";
export const USER_RECIPES_COMMENTS_FAILED = "USER_RECIPES_COMMENTS_FAILED";
export const USER_RECIPES_COMMENTS_COMPLETED = "USER_RECIPES_COMMENTS_COMPLETED";

export const USER_RECIPES_BIN = "USER_RECIPES_BIN";
export const USER_RECIPES_BIN_FAILED = "USER_RECIPES_BIN_FAILED";
export const USER_RECIPES_BIN_COMPLETED = "USER_RECIPES_BIN_COMPLETED";

export const GET_RECIPE = "GET_RECIPE";
export const GET_RECIPE_FAILED = "GET_RECIPE_FAILED";
export const GET_RECIPE_COMPLETED = "GET_RECIPE_COMPLETED";

export const GET_TAGS_COMPLETED = "GET_TAGS_COMPLETED";

export const GET_TAGS = "GET_TAGS";

export const ADD_RECIPE = "ADD_RECIPE";
export const ADD_RECIPE_FAILED = "ADD_RECIPE_FAILED";
export const ADD_RECIPE_COMPLETED = "ADD_RECIPE_COMPLETED";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED";
export const ADD_COMMENT_COMPLETED = "ADD_COMMENT_COMPLETED";

export const EDIT_RECIPE = "EDIT_RECIPE";
export const EDIT_RECIPE_FAILED = "EDIT_RECIPE_FAILED";
export const EDIT_RECIPE_COMPLETED = "EDIT_RECIPE_COMPLETED";

export const DELETE_RECIPE = "DELETE_RECIPE";
export const DELETE_RECIPE_FAILED = "DELETE_RECIPE_FAILED";
export const DELETE_RECIPE_COMPLETED = "DELETE_RECIPE_COMPLETED";

export const LIKE_RECIPE = "LIKE_RECIPE";