import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { Box, Button, Typography, Icon , Modal} from "@material-ui/core";
import { IoShareOutline} from "react-icons/io5";  
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { showToast } from "../../state/ducks/utils/operations";
import "./ProfileInfo.scss";
import CloseIcon from '@material-ui/icons/Close';


  const ProfileInfo = ({ user, onEditClick, editable = true, showToast, isSubscribed }) => {

  const isSharableUser = user.userName && user.subscriptionFee && user.account;

  let links=false;

  if(user.external_links!=null && user.external_links.length)
  {
    user.external_links.map(function(link){
        if(link.checked==true)
        {
          links=true;
        }
    })
  }

  const [openModalLink, setOpenModalLink] = React.useState(false);
  const handleOpenModalLink = () => {
    setOpenModalLink(true);
  };
  const handleCloseModalLink = () => {
    setOpenModalLink(false);
  };


  const copyToClipboard = () => {
    if (!isSharableUser){
      showToast({
        message: "Please set up billing to share profile.",
        type: "error",
      });
      return;
    }
    var tempInput = document.createElement("input");
    tempInput.value = `${window.location.protocol}//${window.location.host}/u/${user.userName}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    showToast({
      message: "Copied to clipboard.",
      type: "success",
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box  className="shareOut" 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={1}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="baseline">
          <Box fontWeight="bold" pr={1}>
            <Typography component="span" variant="h5">
              {user.name}
            </Typography>
          </Box>
          <Box>
            <Typography>{`${user.city || ""}${
              user.state && user.city ? "," : ""
            } ${user.state || ""}`}</Typography>
          </Box>
        </Box>
         <Box className={isSubscribed?"shareProfile":"nosubCrbed"}> 
         {user.external_links!=null && user.external_links.length>0 && links==true?
           <Box className="shareIcon">
          <Button className="smButtomLink"
            fullWidth={false}
            color="primary"
            variant="contained"
            onClick={handleOpenModalLink} >
             <InsertLinkIcon/> 
          </Button>
          </Box> 
          : null}
          <Modal
              open={openModalLink}
              onClose={handleCloseModalLink}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
            <div className="filterModal">
              <h5>Links</h5>
              <Box className="modalClose" onClick={handleCloseModalLink}>
                  <CloseIcon/>
              </Box>
              <Box mt={3} className="otherLinksbt" >
                {user.external_links!=null && user.external_links.length>0?
                    user.external_links.map((link)=>(
                      link.checked==true ?
                    <a href={link.url} target="_blank">
                      {link.name}
                    </a>
                      : null 
                    ))
                    : null }
                
              </Box>
              <Box mt={1} mb={1} textAlign="center" onClick={handleCloseModalLink}>
                  <Button variant="outlined" color="primary" >
                   Close
                  </Button>
                </Box>
             
            </div>
            </Modal>
          <Button className="smButtom"
            fullWidth={false}
            variant="contained"
            color={isSharableUser ? "primary" : "gray"}
            onClick={copyToClipboard} >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box className="shareIcon"><span className="shareText">Share Profile </span><IoShareOutline/> </Box>
                
              </Box>
            </Box>
          </Button>
          </Box>
      </Box>
      <Box color="gray" display="flex" alignItems="center" className="userNameSpc">
        {user.userName ? (
          <Box mr={2}  className="primary-font">
            <Typography align="left">@{user.userName}</Typography>
          </Box>
        ) : null}

        {editable ? (
          <Button className="smButtom"
            fullWidth={false}
            variant="contained"
            color="primary"
            onClick={onEditClick}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box>Edit Profile</Box>
            </Box>
          </Button>
        ) : null} 


      </Box>
      <Box color="gray">
        <Typography align="left">{user.bio}</Typography>
      </Box>
      {editable ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box display="flex" alignItems="center" className="subPriceOut">
            <Typography align="left" className="subPrice" onClick={onEditClick}>
              Subscription Price<span>$ {user.subscriptionFee}</span>
            </Typography>
          </Box>
          <Typography align="left" className="subPrice">
            {get(user, "subscribers", []).length || 0} Subscribers
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { showToast };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
