import * as types from "./types";

export const getUserByUserName = (userName) => ({
  type: types.GET_USER_BY_USERNAME,
  meta: {
    async: true,
    blocking: true,
    path: `user/profile/${userName}`,
    method: "GET",
  },
});

export const getSubscriptions = (id) => ({
  type: types.GET_SUBSCRIPTIONS,
  meta: {
    async: true,
    blocking: true,
    path: `user/${id}/subscriptions`,
    method: "GET",
  },
});

export const getUserById = (id) => ({
  type: types.GET_USER_BY_ID,
  meta: {
    async: true,
    blocking: true,
    path: `user/${id}`,
    method: "GET",
  },
});