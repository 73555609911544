import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
// import EditIcon from '@material-ui/icons/Edit';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PinIcon from "./../../assets/images/pin.svg";
import { get } from "lodash";
import "./RecipeCard.scss";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { RiChat3Line } from "react-icons/ri";
import { useAccordionToggle } from "react-bootstrap";
// import { likeRecipe } from "../../state/ducks/recipe/operations";
// import { showToast } from "../../state/ducks/utils/operations";
import Constant from "../../util/constant";
import Message from "../../util/message";


const RecipeCard = ({ recipeid,userid,name, cookingTime, likecnt, likebyme, coverPic, onEditClick, isPinned,  }) => {

  //console.log(likebyme);
  // const { likeRecipe,showToast } = props;

  // const onLikeClick=async(flag,recipeid,userid)=>{
  //   console.log(flag)
  //   let likeflag;
    
  //   if(flag=='nolike')
  //   {
  //       likeflag=true;
  //   }
  //   else
  //   {
  //       likeflag=false;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("recipeid", recipeid);
  //     formData.append("userid",userid);
  //     formData.append("is_liked", likeflag);
  //     await likeRecipe(formData);
  //     showToast({
  //       message: Message.SuccessMessage.RecipeLiked,
  //       type: "success",
  //       });
  //   } catch (err) {
  //     showToast({
  //       message: get(
  //         err,
  //         "response.data.message",
  //         Message.ErrorMessage.API_ERROR
  //       ),
  //       type: "error",
  //     });
  //   }
  // }

  // const redirectToRecipe = (id) => {
  //   props.history.push(`/recipe/${id}`);
  // };

  return (
    <Box className="recipe-card">
      <img src={coverPic} alt={name} className="recipe-card-img"/>
        {/* 
            <Box
                position="absolute"
                top={10}
                right={10}
                onClick={e => {
                    e.preventDefault();
                    onEditClick()
                }}
            >
                <EditIcon className='edit-icon' />
            </Box>
        */}
      <Box className="recipe-nameplate"> 
          <Box className="cardInnerBx">
           <Box className="recCardIn">
              <Box display="flex" alignItems="center" className="recipe-name">
                {isPinned ? (
                  <Box mr={1}>
                    <img
                      height="16px"
                      width="16px"
                      src={PinIcon}
                      alt={"pinned"}
                    ></img>
                  </Box>
                ) : null}
                <Typography variant="h6">{name}</Typography>
                <Typography className="recipe-time">{cookingTime}</Typography>
              </Box>
              
           </Box>
           </Box>
      </Box>
    </Box>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     busy: state.busy,
//     profile: state.auth.session.user,
//     recipe: state.recipe.recipe,
//   };
// };

// const mapDispatchToProps = {
//   showToast,
//   likeRecipe,
// };

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeCard));

 export default RecipeCard;
