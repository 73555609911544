import withAuthentication from "./hoc/WithAuthentication";
import withoutAuthentication from "./hoc/WithoutAuthentication";

import Layout from "./layout";
import Login from "./pages/Login";
import Signup from "./pages/Singup";
import ConfirmEmail from "./pages/ConfirmEmail";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EditProfile from "./pages/EditProfile";
import AddRecipe from "./pages/AddRecipe";
import EditRecipe from "./pages/EditRecipe";
import Recipe from "./pages/Recipe";
import PublicProfile from "./pages/PublicProfile";
import PreviewProfile from "./pages/PreviewProfile";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import LandingPage from "./pages/LandingPage";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import TermsConditions from "./pages/Terms&Conditions";
import SavedRecipe from "./pages/SavedRecipe";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Tags from './pages/Tags';
import EditTags from './pages/EditTags';
import Comments from './pages/Comments';
const routes = [
  {
    path: "/login",
    component: withoutAuthentication(Login),
  },
  {
    path: "/signup",
    component: withoutAuthentication(Signup),
  },
  {
    path: "/confirm-email",
    component: withoutAuthentication(ConfirmEmail),
  },
  {
    path: "/forgot-password",
    component: withoutAuthentication(ForgotPassword),
  },
  {
    path: "/reset-password",
    component: withoutAuthentication(ResetPassword),
  },
  {
    path: "/welcome",
    component: LandingPage,
  },

  {
    path: "/",
    component: Layout,
  },
];

export const layoutRoutes = [
  {
    path: "/",
    component: withAuthentication(Home),
    exact: true,
  },
  // {
  //   path: "/user/:id",
  //   component: withAuthentication(Profile),
  //   exact: true,
  // },
  {
    path: "/recipe/:id",
    component: withAuthentication(Recipe),
    exact: true,
  },
  {
    path: "/recipe/:id/edit",
    component: withAuthentication(EditRecipe),
    exact: true,
  },
  {
    path: "/saveRecipe",
    component: withAuthentication(SavedRecipe),
    exact: true,
  },
  {
    path: "/recipe",
    component: withAuthentication(AddRecipe),
    exact: true,
  },
  {
    path: "/comments/:recipe_id",
    component: withAuthentication(Comments),
    exact: true,
  },
  // {
  //   path: "/profile",
  //   component: withAuthentication(EditProfile),
  //   exact: true,
  // },
  {
    path: "/u/:userName",
    component: PublicProfile,
    exact: true,
  },
  {
    path: "/p/:userName",
    component: PreviewProfile,
    exact: true,
  },
  {
    path: "/account",
    component: withAuthentication(Account),
    exact: true,
  },
  {
    path: "/checkout",
    component: withAuthentication(Checkout),
    exact: true,
  },
  {
    path: "/terms&cons",
    component: TermsConditions,
    exact: true,
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/tags",
    component: withAuthentication(Tags),
    exact: true,
  },
  {
    path: "/edittags",
    component: withAuthentication(EditTags),
    exact: true,
  },
];

export default routes;
