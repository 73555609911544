export const SUBSCRIBE = "SUBSCRIBE";
export const SUBSCRIBE_FAILED = "SUBSCRIBE_FAILED";
export const SUBSCRIBE_COMPLETED = "SUBSCRIBE_COMPLETED";

export const SUBSCRIBE_RECURRING = "SUBSCRIBE_RECURRING";

export const GET_POPULAR_CHEFS = "GET_POPULAR_CHEFS";
export const GET_POPULAR_CHEFS_FAILED = "GET_POPULAR_CHEFS_FAILED";
export const GET_POPULAR_CHEFS_COMPLETED = "GET_POPULAR_CHEFS_COMPLETED";

export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const ACTIVATE_ACCOUNT_FAILED = "ACTIVATE_ACCOUNT_FAILED";
export const ACTIVATE_ACCOUNT_COMPLETED = "ACTIVATE_ACCOUNT_COMPLETED";

export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";
export const DEACTIVATE_ACCOUNT_FAILED = "DEACTIVATE_ACCOUNT_FAILED";
export const DEACTIVATE_ACCOUNT_COMPLETED = "DEACTIVATE_ACCOUNT_COMPLETED";

export const PAYOUT = "PAYOUT";
export const PAYOUT_FAILED = "PAYOUT_FAILED";
export const PAYOUT_COMPLETED = "PAYOUT_COMPLETED";

export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_FAILED = "GET_BALANCE_FAILED";
export const GET_BALANCE_COMPLETED = "GET_BALANCE_COMPLETED";

export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const UNSUBSCRIBE_FAILED = "UNSUBSCRIBE_FAILED";
export const UNSUBSCRIBE_COMPLETED = "UNSUBSCRIBE_COMPLETED";

export const GET_TOP_CHEFS = "GET_TOP_CHEFS";
export const GET_TOP_CHEFS_FAILED = "GET_TOP_CHEFS_FAILED";
export const GET_TOP_CHEFS_COMPLETED = "GET_TOP_CHEFS_COMPLETED";