import React, { useEffect } from "react";
import {Oval} from 'react-loader-spinner';

 const Loader=(props)=>{
    return (
    <div className="myloader">
        <Oval
    type="Oval"
    color="green"
    height={80} 
    width={80}
    ariaLabel='Please wait.'
    
  />;
    </div>
    )
}

export default Loader;