import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CustomButton from "./../CustomButton/CustomButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = ({
  title,
  children,
  open,
  onConfirm,
  onCancel,
  confirmText = "CONFIRM",
  cancelText = "CANCEL",
  busy,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={onConfirm}
          color="primary"
          fullWidth={false}
          loading={!!busy}
        >
          {confirmText}
        </CustomButton>
        <CustomButton onClick={onCancel} color="primary" fullWidth={false}>
          {cancelText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DialogComponent);
