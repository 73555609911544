import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import {
  withRouter,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

import { layoutRoutes } from "../routes";

import Header from "./Header/Header";
import SideMenu from "./SideMenu/SideMenu";
import BottomMenu from "./BottomMenu/BottomMenu";

import { selectMenu } from "./../state/ducks/utils/operations";

import "./Layout.scss";
import Constant from "../util/constant";

const Layout = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/profile" || location.pathname.includes("/u/")) {
      props.selectMenu(Constant.MENU.PROFILE);
    } else if (location.pathname === "/account") {
      props.selectMenu(Constant.MENU.ACCOUNT);
    }
    else if (location.pathname === "/saveRecipe") {
      props.selectMenu(Constant.MENU.SAVEDRECIPE);
    } else {
      props.selectMenu(Constant.MENU.HOME);
    }
  }, [location]);

  const LayoutComponents = () => (
    <Box height={1} overflow="auto">
      <Header />
      {props.isAuthenticated && <SideMenu />}
      <Box className="app-container">
        <Switch>
          {layoutRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </Box>

      {props.isAuthenticated && <BottomMenu />}
    </Box>
  );

  return <LayoutComponents />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { selectMenu };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
