import * as types from "./types";

export const showToast = (payload) => ({
    type: types.SHOW_TOAST,
    payload
});

export const hideToast = (payload) => ({
    type: types.HIDE_TOAST,
});

export const showLoader = (payload) => ({
    type: types.SHOW_LOADER,
    payload
});

export const hideLoader = (payload) => ({
    type: types.HIDE_LOADER,
});

export const toggleSideMenu = (payload) => ({
    type: types.TOGGLE_SIDE_MENU,
});

export const selectMenu = (payload) => ({
    type: types.SELECT_MENU,
    payload
});

export const contactUs = (payload) => ({
    type: types.CONTACT_US,
    meta: {
        async: true,
        blocking: true,
        path: "util/contact-us",
        method: "POST",
        body: payload
    },
});