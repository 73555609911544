import React from 'react';
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import './ToastComponent.scss';


const ToastComponent = (props) => {
  const { message, type, show, onClose } = props;

  return (
    <Snackbar open={show} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <MuiAlert onClose={onClose} severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default ToastComponent;
