import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Typography, Button } from "@material-ui/core";
import Constant from "../../util/constant";
import "./UserCard.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const UserCard = ({ user, onClick }) => {
  const classes = useStyles();
  return (
    <Box
      className="user-card"
      mb={2}
      style={{
        background: user.coverPic
          ? `url(${Constant.COVER_BUCKET_PATH}/${user.coverPic}) center/cover`
          : `url(${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}) center/cover`,
      }}
      onClick={() => onClick(user?.userName)}
    >
      <Box className="user-backdrop">
        <Box pr={2}>
          <Avatar
            alt={user.name}
            src={
              user.profilePic
                ? `${Constant.PROFILE_BUCKET_PATH}/${
                    user.profilePic
                  }?timestamp=${Date.now()}`
                : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_USER_IMAGE}`
            }
            className={classes.large}
          />
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          className="chefInfo"
        >
          <Box>
            <Box fontWeight="bold" pr={1}>
              <Typography className="white-font chefName">
                {user.name}
              </Typography>
            </Box>
            <Box className="primary-font chefLocation">
              <Typography>{`${user?.city ? user?.city : ""}${
                user?.state ? "," : ""
              } ${user?.state ? user?.state : ""}`}</Typography>
            </Box>
          </Box>
          <Box className="primary-font text-align-right">
            <Button
              fullWidth={false}
              variant="contained"
              color="primary"
              onClick={() => onClick(user?.userName)}
            >
              Open
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserCard;
