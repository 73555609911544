import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const popularChefReducer = createReducer([])({
    [types.GET_POPULAR_CHEFS_COMPLETED]: (state, action) => {
        const subscriptions = get(action, 'payload.data', []);
        return subscriptions;
    }
});

const topChefReducer = createReducer([])({
    [types.GET_TOP_CHEFS_COMPLETED]: (state, action) => {
        const subscriptions = get(action, 'payload.data', []);
        return subscriptions;
    }
});

const balanceReducer = createReducer([])({
    [types.GET_BALANCE_COMPLETED]: (state, action) => {
        const balance = get(action, 'payload.data.balance', {});
        const available = get(balance, 'available', []);
        const pending = get(balance, 'pending', []);
        let amount = 0;
        available.forEach(b => amount +=  get(b, 'amount', 0));
        pending.forEach(b => amount += get(b, 'amount', 0));
        return amount / 100;
    }
});

export default combineReducers({
    popularChefs: popularChefReducer,
    balance: balanceReducer,
    topChefs: topChefReducer
});
