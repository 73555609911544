import React, { useEffect, useState } from "react";
import mainLogo from "../../assets/images/panfavorite.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { FaUserPlus } from "react-icons/fa";
import "./LandingPage.scss";
import { Typography, Box, Button, Container } from "@material-ui/core";

import Message from "../../util/message";
import Constant from "../../util/constant";
import { showToast, contactUs } from "../../state/ducks/utils/operations";
import { getTopChefs } from "../../state/ducks/subscribe/operations";

import StartBox from "../../components/StartBox/StartBox";
import RecipeBox from "../../components/RecipeBox/RecipeBox";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import chefImg from "../../assets/images/chefs.png";
import AddRecipeIcon from "./../../assets/images/add_recipe.svg";
import ShareRecipeIcon from "./../../assets/images/share_recipe.svg";
import PaymentIcon from "./../../assets/images/payment.svg";

// import AddRecipeIcon from "./../../assets/images/step1.png";
// import ShareRecipeIcon from "./../../assets/images/step2.png";
// import PaymentIcon from "./../../assets/images/step3.png";

const LANDING_FORM = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  message: {
    name: "message",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
    },
  },
  firstName: {
    name: "firstName",
    validate: {
      required: {
        value: false,
      },
    },
  },
  lastName: {
    name: "lastName",
    validate: {
      required: {
        value: false,
      },
    },
  },
  phone: {
    name: "phone",
    validate: {
      validate: (value) =>
        !value || Constant.REGEX.PHONE_NUMBER.test(value)
          ? true
          : Message.ErrorMessage.InvalidPhone,
    },
  },
};

const GreenText = ({ variant = "h5", text, textAlign = "center" }) => (
  <Typography
    variant={variant}
    className="primary-font"
    style={{ fontWeight: "bold", textAlign }}
    component="span"
  >
    {text}
  </Typography>
);

const GreenDivider = () => (
  <Box
    height={5}
    width={50}
    className="bg-primary"
    borderRadius={5}
    marginY={1}
    marginX={"auto"}
  ></Box>
);

const LandingPage = ({ history, showToast, busy, contactUs, topChefs = [], getTopChefs }) => {
  const redirectToSignup = () => {
    history.push("/signup");
  };
  const redirectToLogin = () => {
    history.push('/login')
  }
  const [landingForm, setLandingForm] = useState(null);

  useEffect(() => {
    getTopChefs();
  }, [])

  const onFormSubmit = async () => {
    const { email, firstName, lastName, phone, message } =
        landingForm.getValues();
        
    const url = `mailto:support@panfavorite.com?subject=Panfavorite Question from ${encodeURI(firstName+" "+lastName)}&from=me@me.com&body=${encodeURIComponent(message)}`;
    window.open(url, '_blank').focus();
    // try {
    //   if (Object.keys(landingForm.errors).length) return;
    //   const { email, firstName, lastName, phone, message } =
    //     landingForm.getValues();
    //   await contactUs({ email, firstName, lastName, phone, message });
    //   showToast({
    //     message: Message.SuccessMessage.ContactUs,
    //     type: "success",
    //   });
    //   landingForm.reset();
    // } catch (err) {
    //   showToast({
    //     message: get(
    //       err,
    //       "response.data.message",
    //       Message.ErrorMessage.API_ERROR
    //     ),
    //     type: "error",
    //   });
    // }
  };

  return (
    <Box height={1} overflow="auto" className="landing-page">
      <Box color="white" className="landing-page-content">
        <Box className="brand-cover"></Box>
        <Box className="cover-content">
          <Container maxWidth="lg" >
            <Box color="white" className="landingHead">
              <Box className="appName">
                <span>
                  <img src={mainLogo} alt="logo" />
                </span>
                <Typography variant="h5" color="inherit">
                  Panfavorite
              </Typography>
              </Box>
              <Box className="topLinks">
                <Button variant="contained"
                  color="primary"
                  onClick={redirectToLogin}>login</Button>
                <Button onClick={redirectToSignup}> <FaUserPlus /> Signup</Button>
              </Box>
            </Box>
          </Container>
          <Box p="1rem" color="white">
            <Typography variant="h5" color="inherit" className="mainHeading">
              Your Online <GreenText text="Cookbook" />
            </Typography>
          </Box>
          <Box color="white">
            <Typography color="inherit" className="landing-content-container subLine" >
              The recipes you love from the chefs that you trust
            </Typography>
          </Box>
          <Box className="bannButton" color="white">
            <Button
              variant="contained"
              color="primary"
              onClick={redirectToSignup}
            >
              GET STARTED
            </Button>
          </Box>
        </Box>
      </Box>
      <Box flex={1} p="1rem" className="bg-white sectionBox">
        <Box p="1rem" textAlign="center">
          <Typography variant="h5" color="inherit" className="SectionHead">
            It's <GreenText text="Easy" />
          </Typography>
          <GreenDivider></GreenDivider>
        </Box>
        <Box className="step-box-container">
          <StartBox
            title="Upload recipes"
            subTitle="Add your recipes to create your own subscription cookbook"
            img={AddRecipeIcon}
          ></StartBox>
          <StartBox
            title="Share recipes"
            subTitle="Share it with your social media
            following"
            img={ShareRecipeIcon}
          ></StartBox>
          <StartBox
            title="Start Getting Paid"
            subTitle="Collect your monthly payments!
            It’s that easy!"
            img={PaymentIcon}
          ></StartBox>
        </Box>
      </Box>
      <Box flex={1} p="1rem" className="popular-recipe-container sectionBox">
        <Box pt="1rem" textAlign="center">
          <Typography variant="h5" className="SectionHead">
            Top <GreenText text="Chefs" />
          </Typography>
          <Box p="1rem" mb={1} color="gray">
            <Typography color="inherit" className="landing-content-container">
              These are some of the hottest chefs on our platform.
            </Typography>
          </Box>
          <GreenDivider></GreenDivider>
        </Box>
        <Box className="recipe-box-container">
          {topChefs.map((chef, index) => <RecipeBox key={index} title={chef.name} by="" img={chef.profilePic}></RecipeBox>)}
        </Box>
      </Box>
      <Box flex={1} p="1rem" className="chef-container bg-white sectionBoxImg">
        <Box pt="1rem" pb="3rem" textAlign="center">
          <Typography variant="h5" className="SectionHead">
            Our Valued <GreenText text="Community of Chefs" />
          </Typography>
          <Box p="1rem" mb={1} color="gray">
            <Typography color="inherit" className="landing-content-container">
              PanFavorite is the platform to help talented hard-working chef’s and influencers monetize their work and easily
              share their recipes with the world
            </Typography>
          </Box>
          <GreenDivider></GreenDivider>
        </Box>
        <Container maxWidth="lg" className="chefImgBox" >
        <video controls preload="metadata">
          <source src={`${Constant.DEFAULT_IMAGE_PATH}/${Constant.WELCOME_VIDEO}`}
                  type="video/mp4" />
          </video>
        </Container>
      </Box>
      <Box flex={1} p="1rem" className="get-in-touch-container sectionBox">
        <Box p="1rem" textAlign="center">
          <Typography variant="h5" className="SectionHead">
            Get in <GreenText text="Touch" />
          </Typography>
          <Box p="1rem" mb={1} color="gray">
            <Typography color="inherit" className="landing-content-container">
              Have questions,suggestions, or know chefs that would be perfect for PanFavorite?  We’d love to hear from you!
            </Typography>
          </Box>
          <GreenDivider></GreenDivider>
        </Box>
        <HookForm
          defaultForm={{}}
          init={(landingForm) => setLandingForm(landingForm)}
          onSubmit={onFormSubmit}
        >
          {(formMethod) => {
            return (
              <Container maxWidth="sm" className="contForm">
                <Box className="input-container">
                  <TextField
                    className="first-input"
                    formMethod={formMethod}
                    rules={LANDING_FORM.firstName.validate}
                    name={LANDING_FORM.firstName.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="First Name"
                  />
                  <TextField
                    className="second-input"
                    formMethod={formMethod}
                    rules={LANDING_FORM.lastName.validate}
                    name={LANDING_FORM.lastName.name}
                    errors={formMethod?.errors}
                    type={"text"}
                    placeholder="Last Name"
                  />
                </Box>
                {/* <Box className="input-container">
                  <TextField
                    className="first-input"
                    formMethod={formMethod}
                    rules={LANDING_FORM.email.validate}
                    name={LANDING_FORM.email.name}
                    errors={formMethod?.errors}
                    type="text"
                    placeholder="Email Address"
                  />
                  <TextField
                    className="second-input"
                    formMethod={formMethod}
                    rules={LANDING_FORM.phone.validate}
                    name={LANDING_FORM.phone.name}
                    errors={formMethod?.errors}
                    
                    type="text"
                    placeholder="Phone"
                  />
                </Box> */}
                <Box>
                  <TextField
                    className="single-input"
                    formMethod={formMethod}
                    rules={LANDING_FORM.message.validate}
                    name={LANDING_FORM.message.name}
                    errors={formMethod?.errors}
                    type="text"
                    placeholder="Message"
                    multiline={true}
                    rows={3}
                  />
                </Box>
                <Box mt={1}>
                  <CustomButton
                    type="submit"
                    title="SUBMIT"
                    disabled={!formMethod?.formState.isValid}
                    loading={!!busy}
                  />
                </Box>
              </Container>
            );
          }}
        </HookForm>
      </Box>
      <Box>
        <Box className="landFooterOut">
          <Container maxWidth="md" className="landFooter" >
            <Typography color="inherit" >
              © 2021 PanFavorite LLC
            </Typography> 
          </Container> 
        </Box>      
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    topChefs: get(state, 'subscribe.topChefs', []),
  };
};

const mapDispatchToProps = { showToast, contactUs, getTopChefs };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
