import React, { useState } from 'react';
import mainLogo from "../../assets/images/panfavorite.svg";
import { get } from 'lodash';
import { connect } from "react-redux"
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Box,
  Typography
} from "@material-ui/core";

import Message from '../../util/message';
import Constant from "../../util/constant";

import { showToast } from "../../state/ducks/utils/operations";
import { forgotPassword } from "../../state/ducks/auth/operations";

import HookForm from '../../components/HookForm/HookForm';
import TextField from '../../components/TextField/TextField';
import CustomButton from '../../components/CustomButton/CustomButton';

import './ForgotPassword.scss';

const FORGOT_PASSWORD_FORM = {
  email: {
    name: 'email',
    validate: {
      required: {
        value: true,
        message: ((Message.ErrorMessage.EmailEmpty))
      }, pattern: {
        value: Constant.REGEX.email,
        message: ((Message.ErrorMessage.EmailInvalid))
      }
    },
  }
}

const ForgotPassword = (props) => {
  const [forgotPasswordForm, setForgotPasswordForm] = useState(null);

  const onFormSubmit = async () => {
    try {
      if (Object.keys(forgotPasswordForm.errors).length) return;
      const values = forgotPasswordForm.getValues();
      await props.forgotPassword(values);
      props.history.push({
        pathname: '/reset-password', state: {
          email: values.email
        }
      })
    } catch (err) {
      props.showToast({
        message: get(err, 'response.data.message', Message.ErrorMessage.API_ERROR),
        type: 'error'
      })
    }
  };

  const redirectToLogin = () => {
    props.history.push('/login')
  }

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box height={1 / 3} bgcolor="black" color="white" className="brand-cover-container">
        <Box p="1rem" color="white" className="cover-content">
           <Box className="appName">
              <span>
                <img src={mainLogo} alt="logo" />
              </span>
              <Typography variant="h5" color="inherit">
                Panfavorite
              </Typography>
           </Box>
        </Box>
        <Box className="brand-cover"></Box>
        <Typography variant="h3" className="pageName" >Forgot Password</Typography>
      </Box>
      <Box flex={1} className="bglight loginBotton">
        <Container maxWidth="sm">
          <HookForm
            defaultForm={{}}
            init={setForgotPasswordForm}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box display="flex" flexDirection="column" alignItems="center" mt='2rem' height={1}>
                  <Box marginBottom="2rem">
                    <Typography align="center" className='gray-font'>
                      Please enter your registered email address to intialize password reset process.
                    </Typography>
                  </Box>
                  <Box className='auth-card'>
                    <Box marginBottom="1rem">
                      <TextField
                        formMethod={formMethod}
                        rules={FORGOT_PASSWORD_FORM.email.validate}
                        name={FORGOT_PASSWORD_FORM.email.name}
                        errors={formMethod?.errors}
                        type={"text"}
                        placeholder="Email"
                      />
                    </Box>
                    <Box marginBottom="1rem">
                      <CustomButton
                        type="submit"
                        title="SUBMIT"
                        disabled={!formMethod?.formState.isValid}
                        loading={!!props.busy}
                      />
                    </Box>
                  </Box>
                  <Box marginY="1rem" paddingY="1rem" color="black">
                    <Link onClick={redirectToLogin} to=''>
                      <Typography className='black-font login-link'>Go back to login</Typography>
                    </Link>
                  </Box>
                </Box>
              );
            }}
          </HookForm>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
  };
};

const mapDispatchToProps = {
  forgotPassword,
  showToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));