import * as types from "./types";

export const getPopularChefs = (flag,offset) => ({
  type: types.GET_POPULAR_CHEFS,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/popular/${flag}/${offset}`,
    method: "GET",
  },
});

export const subscribe = (id) => ({
  type: types.SUBSCRIBE,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/${id}`,
    method: "PUT",
  },
});

export const activateAccount = (id) => ({
  type: types.ACTIVATE_ACCOUNT,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/stripe/oauth-link`,
    method: "POST",
  },
});

export const deactivateAccount = (id) => ({
  type: types.DEACTIVATE_ACCOUNT,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/stripe/deactivate`,
    method: "PUT",
  },
});

export const getBalance = (id) => ({
  type: types.GET_BALANCE,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/stripe/balance`,
    method: "GET",
  },
});

export const getPayout = (id) => ({
  type: types.PAYOUT,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/stripe/payout`,
    method: "GET",
  },
});

export const unsubscribe = (id) => ({
  type: types.UNSUBSCRIBE,
  meta: {
    async: true,
    blocking: true,
    path: `unsubscribe/${id}`,
    method: "PUT",
  },
});

export const getTopChefs = (id) => ({
  type: types.GET_TOP_CHEFS,
  meta: {
    async: true,
    blocking: true,
    path: `subscribe/top-chefs`,
    method: "GET",
  },
});


export const subRecurr = (body) => ({
  type: types.SUBSCRIBE_RECURRING,
  meta: {
      async: true,
      blocking: true,
      path: `subscribe/subRecurr`,
      method: "POST",
      body
  },
});