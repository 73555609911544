import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Constant from "../../util/constant";
import { getUserByUserName } from "../../state/ducks/user/operations";
import { subscribe } from "../../state/ducks/subscribe/operations";
import { showToast, selectMenu } from "../../state/ducks/utils/operations";
import EditProfile from "../EditProfile";
import Profile from "../Profile";

const PublicProfile = (props) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const {
      match: { params },
      loggedInUser,
    } = props;
    if (loggedInUser?.userName === params?.userName) {
      setEditMode(true);
      setTimeout(props.selectMenu(Constant.MENU.PROFILE));
    }
  }, []);

  return editMode ? <EditProfile /> : <Profile />;
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    isAuthenticated: state.auth.isAuthenticated,
    loggedInUser: state.auth.session.user,
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  showToast,
  getUserByUserName,
  subscribe,
  selectMenu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublicProfile)
);
