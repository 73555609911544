import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Button, Avatar } from "@material-ui/core";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import { get } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { RiChat3Line } from "react-icons/ri";
import Loader from '../../loader';
import {
  updateCoverImage,
  updateProfileImage,
  updateProfile,
} from "../../state/ducks/auth/operations";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { showToast } from "../../state/ducks/utils/operations";
import { getRecipe, deleteRecipe,likeRecipe } from "../../state/ducks/recipe/operations";
import CustomButton from "../../components/CustomButton/CustomButton";
import "./Recipe.scss";
 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Divider = () => (
  <Box my={2} height={0} borderBottom="1px dotted gray"></Box>
);

const Icon = () => <RestaurantIcon className="primary-font" />;

const Counter = ({ count }) => (
  <Box display="inline-flex" justifyContent="center">
    <Typography component="div" className="counter">
      {count}
    </Typography>
  </Box>
);

const Recipe = (props) => { 
  const [open, setOpen] = useState(false);
  const { profile, recipe, getRecipe, history, busy, deleteRecipe,likeRecipe,showToast } = props;
  const [loader, setLoader] = React.useState(false);
  const onEditRecipe = () => {
    history.push(`/recipe/${recipe.id}/edit`);
  };

  const onCancel = () => setOpen(false);

  const openConfirmationDialog = () => setOpen(true);

  const onLikeClick=async(flag,recipeid,userid)=>{
    console.log(flag)
    let likeflag;
    setLoader(true);
    if(flag=='nolike')
    {
        likeflag=true;
    }
    else
    {
        likeflag=false;
    }

    try {
      const formData = new FormData();
      formData.append("recipeid", recipeid);
      formData.append("userid",userid);
      formData.append("is_liked", likeflag);
      await likeRecipe(formData);

      if(flag=='nolike')
      {
        setLoader(false);
        showToast({
          message: Message.SuccessMessage.RecipeLiked,
          type: "success",
          });
      }
      else
      {
        setLoader(false);
        
        showToast({
          message: Message.SuccessMessage.RecipeUnLiked,
          type: "success",
          });
      }

    } catch (err) {
      setLoader(false);
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  }

  const onDeleteClick = async () => {
    try {
      console.log(recipe);
      await deleteRecipe(recipe.id)
      history.push(`/u/${profile.userName}`);
      // history.push('/profile');
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  const openComment=async(props,recipe_id)=>{
    props.history.push(`/comments/${recipe_id}`);
  }

  useEffect(() => {
    const {
      match: { params },
    } = props;
    getRecipe(params.id,profile.id);
  }, []);

  return (
    <>
      <Box height={1}>
      {loader ? <Loader/> : null}
        <Box
          className="cover-banner recipeImage"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          position="relative"
        >
          {/* {recipe.coverPic ? ( */}
            <Box position="absolute" height={1} width={1}>
              <img
                className="cover-img"
                alt={recipe.name}
                src={
                  recipe.coverPic
                    ? `${Constant.RECIPE_BUCKET_PATH}/${recipe.coverPic}?timestamp=${Date.now()}`
                    : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
                }
              />
            </Box>
          {/* ) : null} */}
          <Box className="recipeOwner">
            <Avatar className="userIcon" 
              src={
                recipe.upic
                  ? `${Constant.PROFILE_BUCKET_PATH}/${
                      recipe.upic
                    }?timestamp=${Date.now()}`
                  : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_USER_IMAGE}`
              } />
              <Typography>
                {recipe.uname} 
            </Typography>
          </Box>
        </Box>
        <Container maxWidth="md">
          <Box mt={2} fontWeight="bold" textAlign="left">
            <Box className="recipeTop" >
              <Typography variant="h5">{recipe.name}</Typography>
                  
              {recipe.userId !== profile.id ? (
              <Box className="recipe-likes" >
                <Box className="userLike">
                {recipe.likeflag==false && 
                <>
                <Button
                    className="ml-2 editButtonNew"
                    onClick={()=>onLikeClick('nolike',recipe.id,profile.id)}
                  >
                  <AiOutlineHeart />
                  <Typography>{recipe.likecnt}</Typography>
                  </Button> 
                  
                  </>
                }
                {recipe.likeflag==true &&
                <>
                  <Box>
                  <Button
                    className="ml-2 editButton"
                    onClick={()=>onLikeClick('like',recipe.id,profile.id)} >
                  <AiFillHeart />
                  <Typography>{recipe.likecnt}</Typography>
                  </Button>  
                  </Box> 
                  
                  </>
                }
                
                </Box>
                <Box className="userCmt noRightSP">
                  {/* <RiChat3Line /> <Typography>80</Typography> */}
                  <Button onClick={()=>openComment(props,recipe.id)}>
                  <RiChat3Line /> 
                  <Typography>{recipe.commentcnt}</Typography>
                  </Button>  
                </Box>  
              </Box>)
              :null}

                {recipe.userId === profile.id ? (
                <>
                <Box className="recipe-likes" >
                  <Box className="userLike">
                  <AiFillHeart/> 
                  <Typography>{recipe.likecnt}</Typography>
                  </Box>
                  <Box className="userCmt noRightSP">
                    {/* <RiChat3Line /> <Typography>80</Typography> */}
                    <Button onClick={()=>openComment(props,recipe.id)}>
                  <RiChat3Line /> 
                  <Typography>{recipe.commentcnt}</Typography>
                  </Button>
                  </Box>  
                </Box>
                </>
                ) : null}
             
            </Box>
            <Box >
              <Typography className="primary-font detailsLikes">
                <Box className="timeOut">
                  <Typography>Total: {recipe.cookingTime} Mins</Typography>
                  {recipe.activeTime!==null && recipe.activeTime!==0 ?<Typography>Active Time: {recipe.activeTime} Mins</Typography>:null}
                </Box>

                {recipe.userId === profile.id ? (
                <>
                <div className="iconButtonOut">
                  <Button
                    className="ml-2 editButton"
                    onClick={onEditRecipe}
                  >
                   <AiOutlineEdit/>
                  </Button>
                  <Button
                    className="ml-2 deleteButton"
                    onClick={openConfirmationDialog}
                  >
                    <AiOutlineDelete/>
                  </Button>
                </div>
                </>
              ) : null} 
              </Typography>
              <Box display="flex" mt={1} className="difficultyOut" >
                <Typography>
                  Servings <span className="gray-font">{recipe.servings}</span>
                </Typography>
                <Typography className="difficulty">
                   Difficulty :<span className="gray-font">{recipe.difficulty}</span>
                </Typography>
                {/* { recipe.tags!==null &&
                <Typography className="difficulty">
                   Tags Associated :<span className="gray-font">{JSON.stringify(recipe.tags)}</span>
                </Typography>
              } */}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box mt={2} fontWeight="bold" textAlign="left">
            <Typography variant="h6">Description</Typography>
            <Box mt={1}>
              <Typography className="gray-font">
                {recipe.description}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box mt={2} fontWeight="bold" textAlign="left">
            <Typography variant="h6">Ingredients</Typography>
            <Box mt={1} style={{ color: "grey" }}>
              {get(recipe, "ingredients", []) !== null ?
              get(recipe, "ingredients", []).map((ingredient, index) => (
                <Box display="flex" pt={1} key={index}>
                  <Icon />
                  <Box pl={2} className="instructionsSpace">
                    <Typography>{ingredient.amounts} {ingredient.name}</Typography>
                  </Box>  
                </Box>
              )) : null
            }
            </Box>
          </Box>
          <Divider />
          <Box mt={2} pb={2} fontWeight="bold" textAlign="left">
            <Typography variant="h6">Instructions</Typography>
            <Box mt={1} style={{ color: "grey" }} className="instructionsList">
              {get(recipe, "instructions", [])!== null ?
              get(recipe, "instructions", []).map((ingredient, index) => (
                <Box display="flex" pt={1} key={index}>
                  <Counter count={index + 1} />
                  <Box pl={2} className="instructionsSpace">
                    <Typography component="div">{ingredient}</Typography>
                  </Box>
                </Box>
              )) : null }
            </Box>
          </Box>
        </Container>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">DELETE</DialogTitle>
        <DialogContent>
          <Typography>are you sure you want to delete this recipe? </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={onDeleteClick}
            color="primary"
            fullWidth={false}
            loading={!!busy}
          >
            YES
          </CustomButton>
          <CustomButton
            onClick={onCancel}
            color="primary"
            fullWidth={false}
          >
            NO
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
    recipe: state.recipe.recipe,
  };
};

const mapDispatchToProps = {
  updateCoverImage,
  updateProfileImage,
  showToast,
  updateProfile,
  getRecipe,
  deleteRecipe,
  likeRecipe,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Recipe));
