import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export default function withoutAuthentication(WrappedComponent) {
    const WithoutAuthentication = (props) => {
        if (props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        return (<WrappedComponent {...props} />);
    };

    const { bool } = PropTypes;

    WithoutAuthentication.propTypes = {
        isAuthenticated: bool.isRequired,
    };

    const mapStateToProps = (state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        session: state.auth.session
    });

    return connect(mapStateToProps)(WithoutAuthentication);
}
