export const LOGIN = "auth/LOGIN";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";

export const AUTHENTICATED = "auth/AUTHENTICATED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";

export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_FAILED = "auth/LOGOUT_FAILED";
export const LOGOUT_COMPLETED = "auth/LOGOUT_COMPLETED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";
export const REFRESH_TOKEN_COMPLETED = "auth/REFRESH_TOKEN_COMPLETED";

export const FORGOT_PASSWORD = "auth/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILED = "auth/FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_COMPLETED = "auth/FORGOT_PASSWORD_COMPLETED";

export const VERIFY_RESET_PASSWORD_LINK = "auth/VERIFY_RESET_PASSWORD_LINK";
export const VERIFY_RESET_PASSWORD_LINK_FAILED = "auth/VERIFY_RESET_PASSWORD_LINK_FAILED";
export const VERIFY_RESET_PASSWORD_LINK_COMPLETED = "auth/VERIFY_RESET_PASSWORD_LINK_COMPLETED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";

export const DESTROY = "auth/DESTROY_SESSION";
export const INITIALIZE = "auth/INITIALIZE_SESSION";

export const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAILED = "auth/CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_COMPLETED = "auth/CHANGE_PASSWORD_COMPLETED";

export const GET_PROFILE = "auth/GET_PROFILE";
export const GET_PROFILE_FAILED = "auth/GET_PROFILE_FAILED";
export const GET_PROFILE_COMPLETED = "auth/GET_PROFILE_COMPLETED";

export const SIGNUP = "auth/SIGNUP";
export const SIGNUP_FAILED = "auth/SIGNUP_FAILED";
export const SIGNUP_COMPLETED = "auth/SIGNUP_COMPLETED";

export const CONFIRM_EMAIL = "auth/CONFIRM_EMAIL";
export const CONFIRM_EMAIL_FAILED = "auth/CONFIRM_EMAIL_FAILED";
export const CONFIRM_EMAIL_COMPLETED = "auth/CONFIRM_EMAIL_COMPLETED";

export const UPDATE_COVER_IMAGE = "UPDATE_COVER_IMAGE";
export const UPDATE_COVER_IMAGE_FAILED = "UPDATE_COVER_IMAGE_FAILED";
export const UPDATE_COVER_IMAGE_COMPLETED = "UPDATE_COVER_IMAGE_COMPLETED";

export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE_FAILED = "UPDATE_PROFILE_IMAGE_FAILED";
export const UPDATE_PROFILE_IMAGE_COMPLETED = "UPDATE_PROFILE_IMAGE_COMPLETED";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_TRIAL = "UPDATE_TRIAL";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const UPDATE_PROFILE_COMPLETED = "UPDATE_PROFILE_COMPLETED";

export const GET_USER_BY_ID_COMPLETED = "GET_USER_BY_ID_COMPLETED";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_FAILED = "UPDATE_ACCOUNT_FAILED";
export const UPDATE_ACCOUNT_COMPLETED = "UPDATE_ACCOUNT_COMPLETED";