import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import Constant from "../../../util/constant";

const toastInitialState = {
    show: false,
    message: '',
    type: 'success'
}

const toastReducer = createReducer(toastInitialState)({
    [types.SHOW_TOAST]: (state, action) => ({ show: true, ...action.payload }),
    [types.HIDE_TOAST]: () => toastInitialState,
});

const loaderReducer = createReducer(false)({
    [types.SHOW_LOADER]: () => true,
    [types.HIDE_LOADER]: () => false,
});

const sideMenuReducer = createReducer(false)({
    [types.TOGGLE_SIDE_MENU]: (state) => !state
});

const selectMenuReducer = createReducer(Constant.MENU.HOME)({
    [types.SELECT_MENU]: (state, action) => action.payload
});

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    sideMenu: sideMenuReducer,
    selectedMenu: selectMenuReducer
});
