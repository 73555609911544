import React from "react";
import Button from "@material-ui/core/Button";
import Loader from "../../assets/images/giphy.gif";
import "./CustomButton.scss";
const CustomButton = (props) => {
  let { title, children, disabled, type, className = "", loading, fullWidth = true, variant = "contained", color = "primary" } = props;
  return (
    <Button
      variant={variant}
      color={color}
      className={className}
      type={type}
      onKeyDown={props.onKeyDown}
      disabled={disabled || loading}
      onClick={props.onClick}
      fullWidth={fullWidth}
    >
      {loading ? (
        <div className="btn-loader">
          <img src={Loader} height={16} width={16} alt='loader' />
        </div>
      ) : (
        title
      )}
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  className: "submitBtn",
  loading: false,
};

export default CustomButton;
