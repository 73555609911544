import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showToast } from "../../state/ducks/utils/operations";
import Message from "../../util/message";
import SendIcon from '@material-ui/icons/Send';
import { Container, Box, Typography, TextField, Avatar, Button, Link,  } from "@material-ui/core";
import { get } from "lodash";
import { getUserRecipesComments, getRecipe,addComment } from "../../state/ducks/recipe/operations";
import Loader from '../../loader';

const SubComment= (props) => {
    const {
        showToast,  
        profileid,
        recipeid,
        addComment,
        onAddComment,
        commentid,
      } = props;

    const [isActive, setActive] = useState(props.isActive);
    const [subcom,setSubCom]=useState(props.subcom || []);
    const [comment, setComment] = useState("");
    const [loader, setLoader] = React.useState(false);

    const handleToggle = async (id, event) => {
        setActive(!isActive);      
    }

    const onChange=(e)=>{
        setComment(e.target.value);
    }

    const  saveSubComment=async(parentid)=>{
        setLoader(true);
        try {
        const formData = new FormData();
        formData.append("user_id", profileid);
        formData.append("recipe_id", recipeid);
        formData.append("comments", comment);
        formData.append("parent_comment_id", parentid);

        await addComment(formData);
        
        setLoader(false);

        showToast({
        message: Message.SuccessMessage.CommentCreated,
        type: "success",
        });

        onAddComment && onAddComment(true);

        } catch (err) {
            setLoader(false);
            showToast({
            message: get(
                err,
                "response.data.message",
                Message.ErrorMessage.API_ERROR
            ),
            type: "error",
            });
        }
    }

    return (
        <>
         {loader ? <Loader/> : null}
        <Link className="linkText paddingLeft" onClick={handleToggle} >Reply</Link>  {subcom.length>0?<span className="repyCount"><em>{subcom.length}</em> Replies</span>:null}
        <Box className={isActive ? "replyWrapper" : null} >
        <Box className="showReply paddingLeft">
            {subcom.length>0 ?
            subcom.map((subcom) => (
            <Box className="allcmts">
                <Box className="singlerply">
                    <Box className="cmtBoxTop">
                        <Box pr={1}>
                            <Avatar 
                            />
                        </Box>
                        <Box>
                            <Typography className="cmtUser">
                                @{subcom.uname}
                            </Typography>
                            <Typography className="cmtDate">
                                {subcom.createdAt}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="cmtBoxBott">
                    {subcom.comment}
                    </Box>
                </Box>

            </Box>
            )) : null}
            <TextField
            className="cmtTextfild"
            type={"text"}
            placeholder="Add Comment"
            rows={3}
            multiline
            onChange={(e)=>onChange(e)}
            />
            <Button 
            className="sendbtn"
            fullWidth={false}
            variant="contained"
            color="primary"
            onClick={()=>saveSubComment(commentid)}
            >
            <SendIcon/>  
            Post
            </Button>  
        </Box>
    </Box>
    </>
    );
};

const mapStateToProps = (state) => {
    return {
    //   profile: state.auth.session.user,
      busy: state.busy,
      user: state.user.user,
      profile: state.auth.session.user,
      isAuthenticated: state.auth.isAuthenticated,
    };
};


const mapDispatchToProps = {
    showToast,
    getUserRecipesComments,
    getRecipe,
    addComment,
 };

export default connect(mapStateToProps, mapDispatchToProps)(SubComment);
