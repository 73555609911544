import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Card, Button } from "@material-ui/core";
import SpanTags from "../../components/SpanTags/SpanTags";
import { showToast } from "../../state/ducks/utils/operations";
import Message from "../../util/message";
import { connect } from "react-redux";
import { addRecipe } from "../../state/ducks/recipe/operations";
import { get } from "lodash";

import {
    useLocation,
} from "react-router-dom";
import Loader from '../../loader';


const Tags = (props) => {
    const { profile, addRecipe, showToast, history } = props;
    // const state = store.default.getState();
    const [prevData, setPrevData] = useState([]);
    const [disable, setDisable] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    const location = useLocation();
    useEffect(() => {
        console.log(location.state);
        setPrevData(location.state);
    }, [location]);

    var keys=[];
    const onFormSubmit = async () => {
        setDisable(true)
        setLoader(true);
        console.log(profile);
        let tags = localStorage.getItem('tags');
        const arr = JSON.parse(tags);
        try {
        if(arr.length<3)
        {
            localStorage.setItem('addrecipe','addrecipe');
            localStorage.removeItem('tags');
            setLoader(false);
            showToast({
                message:Message.ErrorMessage.TagsMessage,
                type: "error",
              });
        }
        else
        {
            console.log(prevData.arr1.amounts);
            const formData = new FormData();
            formData.append("userId", prevData.arr1.userId);
            formData.append("difficulty",prevData.arr1.difficulty);
            formData.append("coverPic", prevData.arr1.coverPic);
            formData.append("ingredients", prevData.arr1.ingredients);
            formData.append("instructions", prevData.arr1.instructions);
            formData.append("amounts", prevData.arr1.amounts);
            formData.append("isPinned",prevData.arr1.isPinned);
            formData.append("tags", localStorage.getItem('tags'));
            
            for (const key of Object.keys(prevData.arr1.key)) {
                if(key=='activeTime' && (prevData.arr1.key[key]=="" || prevData.arr1.key[key]==null))
                {
                    formData.append(key, 0);
                }
                else
                {
                    formData.append(key, prevData.arr1.key[key]);
                }
                
                keys[key]=prevData.arr1.key[key];
                //  formData.append(key, prevData.arr1.key[key]);
                // keys[key]=prevData.arr1.key[key];
                // console.log(key);
              }

            await addRecipe(formData);
            setLoader(false);
            showToast({
            message: Message.SuccessMessage.RecipeCreated,
            type: "success",
            });
            localStorage.removeItem('tags');
            
            history.push(`/u/${profile.userName}`);
        }
        } catch (err) {
            setLoader(false);
            showToast({
            message: get(
                err,
                "response.data.message",
                Message.ErrorMessage.API_ERROR
            ),
            type: "error",
            });
        }

    }

    return (

        <Container maxWidth="md" className="pageContainer">
            <Box className="tagsOut">
            {loader ? <Loader/> : null} 
                <Typography variant="h6" className="sectHeading">
                    TAGS
                </Typography>
                <Typography className="greyFont">
                    Select atleast 3 tags so your recipes easy to find!
                </Typography>
                <Typography className="tagsTitle">
                    Ingredients
                </Typography>
                <Box className="myTags">
                    <SpanTags isActive={false} id='Chicken' content='Chicken' ></SpanTags>
                    <SpanTags isActive={false} id='Seafood' content='Seafood'></SpanTags>
                    <SpanTags isActive={false} id='Pork' content='Pork'></SpanTags>
                    <SpanTags isActive={false} id='Beef' content='Beef'></SpanTags>
                    <SpanTags isActive={false} id='Pasta' content='Pasta'></SpanTags>
                    <SpanTags isActive={false} id='Dairy' content='Dairy' ></SpanTags>
                    <SpanTags isActive={false} id='Turkey' content='Turkey'></SpanTags>
                    <SpanTags isActive={false} id='Rice' content='Rice'></SpanTags>
                    <SpanTags isActive={false} id='Tofu' content='Tofu'></SpanTags>
                    <SpanTags isActive={false} id='Seitan' content='Seitan'></SpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Dietary Restrictions
                </Typography>
                <Box className="myTags">
                    <SpanTags isActive={false} id='Gluten-Free' content='Gluten-Free'></SpanTags>
                    <SpanTags isActive={false} id='Dairy-Free' content='Dairy-Free'></SpanTags>
                    <SpanTags isActive={false} id='Vegitarian' content='Vegitarian'></SpanTags>
                    <SpanTags isActive={false} id='Vegan' content='Vegan'></SpanTags>
                    <SpanTags isActive={false} id='Healthy' content='Healthy'></SpanTags>
                    <SpanTags isActive={false} id='Low Fat' content='Low Fat'></SpanTags>
                    <SpanTags isActive={false} id='Sugar-Free' content='Sugar-Free'></SpanTags>
                    <SpanTags isActive={false} id='Kosher' content='Kosher'></SpanTags>
                    <SpanTags isActive={false} id='Low Calorie' content='Low Calorie'></SpanTags>
                    <SpanTags isActive={false} id='High Protein' content='High Protein'></SpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Cuisine
                </Typography>
                <Box className="myTags">
                    <SpanTags isActive={false} id='Chinese' content='Chinese'></SpanTags>
                    <SpanTags isActive={false} id='Italian' content='Italian'></SpanTags>
                    <SpanTags isActive={false} id='French' content='French'></SpanTags>
                    <SpanTags isActive={false} id='Indian' content='Indian'></SpanTags>
                    <SpanTags isActive={false} id='Mediterranean' content='Mediterranean'></SpanTags>
                    <SpanTags isActive={false} id='Maxican' content='Maxican'></SpanTags>
                    <SpanTags isActive={false} id='Thai' content='Thai'></SpanTags>
                    <SpanTags isActive={false} id='Vietnamese' content='Vietnamese'></SpanTags>
                    <SpanTags isActive={false} id='Spanish' content='Spanish'></SpanTags>
                    <SpanTags isActive={false} id='Japanese' content='Japanese'></SpanTags>
                    <SpanTags isActive={false} id='Fusion' content='Fusion'></SpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Cooking Method
                </Typography>
                <Box className="myTags">
                    <SpanTags isActive={false} id='Slow Cooker' content='Slow Cooker'></SpanTags>
                    <SpanTags isActive={false} id='Pressure Cooker' content='Pressure Cooker'></SpanTags>
                    <SpanTags isActive={false} id='Air Frier' content='Air Frier'></SpanTags>
                    <SpanTags isActive={false} id='Grill' content='Grill'></SpanTags>
                    <SpanTags isActive={false} id='Oven' content='Oven'></SpanTags>
                    <SpanTags isActive={false} id='Stir Fry' content='Stir Fry'></SpanTags>
                    <SpanTags isActive={false} id='One Pan' content='One Pan'></SpanTags>
                </Box>


                <Typography className="tagsTitle">
                    Miscellaneous
                </Typography>
                <Box className="myTags">
                    <SpanTags isActive={false} id='Breakfast' content='Breakfast'></SpanTags>
                    <SpanTags isActive={false} id='Lunch' content='Lunch'></SpanTags>
                    <SpanTags isActive={false} id='Dinner' content='Dinner'></SpanTags>
                    <SpanTags isActive={false} id='Dessert' content='Dessert'></SpanTags>
                    <SpanTags isActive={false} id='Appetizers' content='Appetizers'></SpanTags>
                    <SpanTags isActive={false} id='Sandwich' content='Sandwich'></SpanTags>
                    <SpanTags isActive={false} id='Soup' content='Soup'></SpanTags>
                    <SpanTags isActive={false} id='Salad' content='Salad'></SpanTags>
                    <SpanTags isActive={false} id='Casseroles' content='Casseroles'></SpanTags>
                    <SpanTags isActive={false} id='Smoothie' content='Smoothie'></SpanTags>
                    <SpanTags isActive={false} id='Snack' content='Snack'></SpanTags>
                    <SpanTags isActive={false} id='Beverage' content='Beverage'></SpanTags>
                    <SpanTags isActive={false} id='Sauce' content='Sauce'></SpanTags>
                </Box>
                <Button className="tagButton" disabled={disable} onClick={onFormSubmit}>
                    SAVE
                </Button>

            </Box>
        </Container>
    );

};

const mapStateToProps = (state) => {
    return {
        busy: state.busy,
        profile: state.auth.session.user,
      };
  };
  
  const mapDispatchToProps = { showToast,addRecipe };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Tags);
  