import React from 'react';
import { Typography, Box } from "@material-ui/core";

const StartBox = ({ title, subTitle, img }) => (
    <Box className="step-box">
        <img height="50px" width="50px" src={img} alt={title}></img>
        <Box mt={2}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {title}
            </Typography>
        </Box>
        <Box color="gray">
            <Typography>{subTitle}</Typography>
        </Box>
    </Box>
);

export default StartBox;