import React,{useEffect} from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import { hideToast } from "./state/ducks/utils/operations";
import ToastComponent from "./components/ToastComponent/ToastComponent";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#40D44A',
    },
    secondary: {
      main: '#E43E2D',
    },
  },
});



function App(props) {
  const { toast, hideToast} = props;
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastComponent
          show={toast.show}
          message={toast.message}
          type={toast.type}
          onClose={hideToast}
        />
        <BrowserRouter>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.utils.toast,
  };
};

const mapDispatchToProps = { hideToast };

export default connect(mapStateToProps, mapDispatchToProps)(App);
