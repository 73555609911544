import React, { useEffect, useState,useRef  } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Select, MenuItem, Button, Modal, NativeSelect } from "@material-ui/core";
import { isEmpty } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Constant from "../../util/constant";
import coverPic from "../../assets/images/cover.jpg";
import { getUserById, getUserByUserName,} from "../../state/ducks/user/operations";
import { showToast } from "../../state/ducks/utils/operations";
import { getUserRecipes,likeRecipe,getTopRecipeTags,getUserLockedRecipes } from "../../state/ducks/recipe/operations";
import { subscribe, unsubscribe } from "../../state/ducks/subscribe/operations";
import LockIcon from '@material-ui/icons/Lock';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu'; 
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SortRoundedIcon from '@material-ui/icons/SortRounded';

import Message from "../../util/message";

import Avatar from "../../components/Avatar/Avatar";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import SubscribeDetailCard from "../../components/SubscribeDetailCard/SubscribeDetailCard";
import SubscribeBulletList from "../../components/SubscribeBulletList/SubscribeBulletList";
import CustomButton from "../../components/CustomButton/CustomButton";
import CloseIcon from '@material-ui/icons/Close';
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import Loader from '../../loader';
import { RiChat3Line } from "react-icons/ri";

import "./PreviewProfile.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewProfile = (props) => {


  const {
    user,
    getUserById,
    busy,
    getUserRecipes,
    getUserLockedRecipes,
    recipes,
    recipelocked,
    profile,
    showToast,
    subscribe,
    history,
    unsubscribe,
    getUserByUserName,
    getTopRecipeTags,
    likeRecipe,
    tags
  } = props;

  

  useEffect(() => {
   
    const {
      match: { params },
    } = props;

        getUserById(profile.id);
        getUserLockedRecipes(profile.id);
      
  },[]);

  

  const renderSubscriptionDetail = (links) => {
    return (
      <>
        <Box flex={1} mt={4} className="midCard">
          <SubscribeDetailCard
            subscriptionEnabled={true}
            subscriptionFee={user.subscriptionFee}
            // onSubscribe={onSubscribeClick}
            loggedInUser={false}
            disabled={true}
          />
          <Box className="subscribeOut">
          <SubscribeBulletList />
          </Box>
        </Box>
        {/* <Box mt={3} className="otherLinksbt" >
          {links!=null && links.length>0?
          links.map((link)=>(
            link.checked==true?
          <Button variant="contained" color="primary" >
            {link.name}
            <a href={link.url}/>
          </Button>
            : null 
          ))
          : null }
        </Box> */}
        {recipelocked.length>0 ?
         recipelocked.map((rlock) =>(
        <Box mt={2} >
            <Box className="recipe-card">
              <img src={
              rlock.coverPic
                ? `${Constant.RECIPE_BUCKET_PATH}/${
                  rlock.coverPic
                  }?timestamp=${Date.now()}`
                : `${Constant.DEFAULT_IMAGE_PATH}/${Constant.DEFAULT_RECIPE_IMAGE}`
            } className="recipe-card-img" />
              <Box className="recipe-nameplate"> 
                <Box className="lockRecipe">
                     <LockIcon/>
                </Box>
                <Box className="cardInnerBx">
                  <Box className="recCardIn">
                      <Box display="flex" alignItems="center" className="recipe-name">
                        <Box className="pinOut">
                          <Typography variant="h6">{rlock.name}</Typography>
                        </Box>
                        <Typography className="recipe-time">{rlock.cookingTime} Min</Typography>
                      </Box>
                  </Box>
                </Box>
              </Box>             
            </Box>
          </Box>
         )) : null}
      </>
    );
  };

  return (
    <>
      {isEmpty(user) ? (
        <Box
        id="header"
          display="flex"
          height={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">
            {busy ? "Loading..." : "Profile not found!!"}
          </Typography>
        </Box>
      ) : (
        <Box height={1}>
          <Box
            className="cover-banner"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            position="relative"
          >
            <Container maxWidth="md">
              <Box className="profileOut">
                <Box position="absolute" bottom={"-50%"} zIndex={1}>
                  <Avatar
                    editable={false}
                    pic={user.profilePic}
                    name={user.name}
                  />
                </Box>
              </Box>
            </Container>
            <Box position="absolute" height={1} width={1}>
              <img
                className="cover-img"
                alt={user.name}
                src={
                  user.coverPic
                    ? `${Constant.COVER_BUCKET_PATH}/${
                        user.coverPic
                      }?timestamp=${Date.now()}`
                    : `${Constant.DEFAULT_IMAGE_PATH}/${
                        Constant.DEFAULT_RECIPE_IMAGE
                      }?timestamp=${Date.now()}`
                }
              />
            </Box>
          </Box>
          <Container maxWidth="md" className="pageContainer">
          <Box className="subscribedBtOut">
          <Box flex={1} mt={12} className="firstCard">
                <ProfileInfo user={user} editable={false}/>
              </Box>
              {
              renderSubscriptionDetail(user.external_links)
              }
              
            
           </Box>
          </Container>
          
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth.session.user,
    busy: state.busy,
    recipes: state.recipe.recipes,
    user: state.user.user,
    isAuthenticated: state.auth.isAuthenticated,
    tags: state.recipe.tags,
    recipelocked: state.recipe.recipelocked,
  };
};

const mapDispatchToProps = {
  showToast,
  getUserById,
  getUserRecipes,
  getUserLockedRecipes,
  getUserByUserName,
  subscribe,
  unsubscribe,
  likeRecipe,
  getTopRecipeTags
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewProfile)
);
