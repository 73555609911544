export const GET_USER_BY_USERNAME = "GET_USER_BY_USERNAME";
export const GET_USER_BY_USERNAME_FAILED = "GET_USER_BY_USERNAME_FAILED";
export const GET_USER_BY_USERNAME_COMPLETED = "GET_USER_BY_USERNAME_COMPLETED";

export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_FAILED = "GET_SUBSCRIPTIONS_FAILED";
export const GET_SUBSCRIPTIONS_COMPLETED = "GET_SUBSCRIPTIONS_COMPLETED";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";
export const GET_USER_BY_ID_COMPLETED = "GET_USER_BY_ID_COMPLETED";