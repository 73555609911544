import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography, Tab, Tabs, Link,  Checkbox,
  FormControlLabel } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";

import Constant from "../../util/constant";
import Message from "../../util/message";
import { showToast } from "../../state/ducks/utils/operations";
import {
  activateAccount,
  deactivateAccount,
  getBalance,
  getPayout,
} from "../../state/ducks/subscribe/operations";
import { updateAccount, logout,updateTrial } from "../../state/ducks/auth/operations";
import { getUserById } from "../../state/ducks/user/operations";

import "./Account.scss";
import TabPanel from "../../components/TabPanel/TabPanel";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";

const tabProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const ACCOUNT_FORM = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      validate: {
        length: (value) =>
          !value ||
          (value && value.length >= 8) ||
          "Minimum 8 characters required",
        hasSpecialChar: (value) =>
          !value ||
          (value && value.match(Constant.REGEX.SPECIAL_CHAR)) ||
          "Minimum 1 special character required",
        hasNumbers: (value) =>
          !value ||
          (value && value.match(Constant.REGEX.CONTAIN_NUMBER)) ||
          "Minimum 1 number required",
        hasLowerUpper: (value) =>
          !value ||
          (value && value.match(Constant.REGEX.LOWERCASE_UPPERCASE)) ||
          "Minimum upper and lower case character required",
      },
    },
  },
  currentPassword: {
    name: "currentPassword",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.CurrentPassword,
      },
    },
  },
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
      pattern: {
        value: Constant.REGEX.Alphanumeric,
        message: Message.ErrorMessage.AlphanumericInvalid,
      },
    },
  },
};

const Account = ({
  profile,
  busy,
  showToast,
  activateAccount,
  deactivateAccount,
  getUserById,
  getBalance,
  balance,
  getPayout,
  updateAccount,
  logout,
  location,
  updateTrial
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const [accountLoading, setAccountLoading] = React.useState(false);
  const [form, setForm] = React.useState();
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onPayoutClick = async () => {
    try {
      const response = await getPayout();
      showToast({
        message: get(
          response,
          "data.message",
          "Payout is requested successfully"
        ),
        type: "success",
      });
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

  useEffect(() => {
    const tab = get(location, "state.tab", null);
    if (tab === "BANKING INFO") {
      setValue(1);
    }
    getUserById(profile.id);
    getBalance();
  }, []);

  const handleAccount = async () => {
    try {
      setAccountLoading(true);
      if (!profile.account) {
        const response = await activateAccount();
        const url = get(response, "data.url", "");
        window.open(url, "_self");
      } else {
        await deactivateAccount();
        await getUserById(profile.id);
      }
      setAccountLoading(false);
    } catch (err) {
      setAccountLoading(false);
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };

 
  const onFormSubmit = async () => {
    try {
      if (Object.keys(form.errors).length) return;
      const { name, email, password, currentPassword } = form.getValues();
      const response = await updateAccount({
        name,
        email,
        password,
        currentPassword,
      });
      const isEmailUpdated = get(response, "data.email", false);
      if (isEmailUpdated) {
        showToast({
          message: "Email is updated. Login again and verify your email",
          type: "success",
        });
        logout();
      } else {
        showToast({
          message: "Profile is updated successfully.",
          type: "success",
        });
        await getUserById(profile.id);
      }
    } catch (err) {
      showToast({
        message: get(
          err,
          "response.data.message",
          Message.ErrorMessage.API_ERROR
        ),
        type: "error",
      });
    }
  };



  return (
    <Container maxWidth="md" className="whtInput">
      <Box mt={4}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="ACCOUNT INFO" {...tabProps(0)} />
          <Tab label="BANKING INFO" {...tabProps(1)} />
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} className="accountOut">
          <HookForm
            defaultForm={{
              name: profile.name,
              email: profile.email,
            }}
            init={(form) => setForm(form)}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <Box>
                  <Box mt={3}>
                    <Typography align="left" className="field-title">
                      Name
                    </Typography>
                    <Box mt={1}>
                      <TextField
                        formMethod={formMethod}
                        rules={ACCOUNT_FORM.name.validate}
                        name={ACCOUNT_FORM.name.name}
                        errors={formMethod?.errors}
                        type={"text"}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography align="left" className="field-title">
                      Email
                    </Typography>
                    <Box mt={1}>
                      <TextField
                        formMethod={formMethod}
                        rules={ACCOUNT_FORM.email.validate}
                        name={ACCOUNT_FORM.email.name}
                        errors={formMethod?.errors}
                        type={"text"}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography align="left" className="field-title">
                      New Password
                    </Typography>
                    <Box mt={1}>
                      <TextField
                        formMethod={formMethod}
                        rules={ACCOUNT_FORM.password.validate}
                        name={ACCOUNT_FORM.password.name}
                        errors={formMethod?.errors}
                        type={"password"}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography align="left" className="field-title">
                      Current Password
                    </Typography>
                    <Box mt={1}>
                      <TextField
                        formMethod={formMethod}
                        rules={ACCOUNT_FORM.currentPassword.validate}
                        name={ACCOUNT_FORM.currentPassword.name}
                        errors={formMethod?.errors}
                        type={"password"}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <CustomButton
                      type="submit"
                      color="primary"
                      fullWidth={true}
                      loading={busy}
                      disabled={!formMethod?.formState.isValid}
                    >
                      SAVE YOUR DETAILS
                    </CustomButton>
                  </Box>
                  <Box mt={4}>
                    <Link href={`mailto:support@panfavorite.com`}>
                      <Typography className="black-font underline">
                        CONTACT US
                      </Typography>
                    </Link>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Link onClick={logout}>
                      <Typography className="black-font underline">
                        LOGOUT 
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              );
            }}
          </HookForm>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className="accountOut">
          <Box mt={3} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography align="left" variant="h6">
                BANKING INFO
              </Typography>
              
            </Box>
            <CustomButton
              className="smButtom"
              type="button"
              color="primary"
              fullWidth={false}
              onClick={handleAccount}
              loading={accountLoading}
            >
              {profile.account ? "DEACTIVATE" : "ACTIVATE"}
            </CustomButton>
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between" className="acbalance">
            <Typography align="left" variant="h6">
              Account Balance
            </Typography>
            <Typography align="left" variant="h6">
              $ {balance}
            </Typography>
          </Box>
         
          <Box mt={2}>
            {/* <CustomButton 
              className="myButton"
              type="button"
              color="primary"
              disabled={!profile.account}
              loading={!!busy}
              onClick={onPayoutClick}
            >
              TRANSFER BALANCE
            </CustomButton> */}
          </Box>

          {profile.account ? (
            <Box className={'stripe-link'}>
              <Link className="myButtonStipr" href={`https://dashboard.stripe.com/`} target="_blank" rel="noopener noreferrer">
                <Typography >
                  Stripe Dashboard
                </Typography>
              </Link>
            </Box>
          ) : null}

          {/* <Typography className="noteText">
            Funds will automatically transfer to your bank account 7-10 days after payment
          </Typography>   */}
          <Typography className="noteText">
          We have partnered with Stripe to process payments for our Chefs.  Stripe is a trusted payment partner for some of the largest companies in the world like Shopify, Peloton, and Instacart. Tap activate and enter your billing info if you’d like to start selling your recipes! 
          </Typography>  

          <Box mt={4}>
                    <Link href={`mailto:support@panfavorite.com`}>
                      <Typography className="black-font underline">
                        CONTACT US
                      </Typography>
                    </Link>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Link onClick={logout}>
                      <Typography className="black-font underline">
                        LOGOUT 
                      </Typography>
                    </Link>
                  </Box>
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.busy,
    profile: state.auth.session.user,
    balance: state.subscribe.balance,
  };
};

const mapDispatchToProps = {
  showToast,
  activateAccount,
  deactivateAccount,
  getUserById,
  getBalance,
  getPayout,
  updateAccount,
  updateTrial,
  logout,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
